import React, {useEffect, useState} from 'react';
import {getHeaderData, showPageLoadingTimeout, URL_DATA_BASE} from "../../../../settings";

const StoreSettingsKaspi = (props) => {

    const [store, setUpdateStore] = useState(
        {
            ...props.SelectedStore,
            updateStoreData: false
        }
    );

    useEffect(()=> {
        setUpdateStore({
            ...props.SelectedStore,
            updateStoreData: false
        });
    }, [props.SelectedStore])

    const changeValueStore = (key, value) => {
        let newValueStore = {};
        newValueStore[key] = value;
        newValueStore.updateStoreData = true;
        setUpdateStore(
            {
                ...store,
                ...newValueStore
            }
        )
    }

    const changeDumping = () => {
        changeValueStore('dumping', !store.dumping);
    }

    const changeDontDumpingPlace = () => {
        changeValueStore('dontDumpingPlace', !store.dontDumpingPlace);
    }

    const changeControlOffersBalance = () => {
        changeValueStore('controlOffersBalance', !store.controlOffersBalance);
    }

    const selectCurrentStore = () => {
        setUpdateStore(
            {
                ...props.SelectedStore,
                updateStoreData: false
            }
        )
    }

    const changeDumpingBrands = (valueData) => {
        let valueDataString = '';
        if (valueData) {
            valueData = JSON.parse(valueData);
            valueData.forEach(el => {
                valueDataString += el.value + ','
            })
        }
        if (valueDataString) {
            valueDataString = valueDataString.slice(0, -1)
        }
        if (valueDataString !== store.dumpingBrands) {
            changeValueStore('dumpingBrands', valueDataString)
        }
    }

    const changeDumpingSkipStores = (valueData) => {
        let valueDataString = '';
        if (valueData) {
            valueData = JSON.parse(valueData);
            valueData.forEach(el => {
                valueDataString += el.value + ','
            })
        }
        if (valueDataString) {
            valueDataString = valueDataString.slice(0, -1)
        }
        if (valueDataString !== store.skipStoresIdDumping) {
            changeValueStore('skipStoresIdDumping', valueDataString)
        }
    }

    const updateDataStore = async () => {
        const button = document.querySelector('#update_date_store');
        const updateData = {};
        const currentStore = props.SelectedStore;

        if (store.active !== currentStore.active) updateData.active = store.active;
        if (store.name !== currentStore.name) {
            updateData.name = store.name.trim() || props.SelectedStore.name;
        };
        if (store.dumping !== currentStore.dumping) updateData.dumping = store.dumping;
        if (store.stepDumping !== currentStore.stepDumping) updateData.stepDumping = store.stepDumping;
        if (store.dumpingBrands !== currentStore.dumpingBrands) updateData.dumpingBrands = store.dumpingBrands;
        if (store.skipStoresIdDumping !== currentStore.skipStoresIdDumping) updateData.skipStoresIdDumping = store.skipStoresIdDumping;
        if (store.dumpingPlace !== currentStore.dumpingPlace) updateData.dumpingPlace = store.dumpingPlace;
        if (store.dontDumpingPlace !== currentStore.dontDumpingPlace) updateData.dontDumpingPlace = store.dontDumpingPlace;

        if (store.login !== currentStore.login) updateData.login = store.login;
        if (store.password !== currentStore.password) updateData.password = store.password;

        if (store.controlOffersBalance !== currentStore.controlOffersBalance) updateData.controlOffersBalance = store.controlOffersBalance;

        if (Object.keys(updateData).length) {
            button.setAttribute('data-kt-indicator', 'on');
            if (updateData.stepDumping) {
                let stepDumping = parseInt(updateData.stepDumping);
                if (stepDumping <= 0) stepDumping = 1;
                updateData.stepDumping = stepDumping;
            }
            try {
                showPageLoadingTimeout(500);
                const response = await fetch(`${URL_DATA_BASE}/${store.marketplaceName}/store/update/${store.id}`,
                    getHeaderData('POST', updateData, props.UserJWT));

                if (response.status === 200) {
                    successQueryUpdateStore(`Обновление кабинета ${store.name} успешно завершено`);
                    const body = await response.json();
                    props.changeMarketplaceStores(body);
                } else {
                    let description = `Обновление кабинета ${store.name} завершилось с ошибками`;
                    errorQueryUpdateStore(description);
                }
            } catch (error) {
                console.log(error);
            }

            setTimeout(()=> {button.removeAttribute('data-kt-indicator')}, 3000)

        } else {
            selectCurrentStore();
        }

    }

    useEffect(() => {
        if (store.dumping && store.active) {
            const inputSkipStores = document.querySelector("#skip_stores_select");
            new window.Tagify(inputSkipStores);
            const selectedElement = document.querySelector('#brands_select');
            const updateBrandsList = async () => {
                const resp = await fetch(`${URL_DATA_BASE}/${store.marketplaceName}/offer/filter/${store.id}`,
                    getHeaderData('GET', null, props.UserJWT));
                const body = await resp.json();
                let brands = []
                if (!body?.detail) {
                    brands = body;
                }
                brands = brands.filter(el=> el.name !== null);
                new window.Tagify(selectedElement, {
                    whitelist: brands,
                    maxTags: 10,
                    enforceWhitelist: true,
                    dropdown: {
                        maxItems: 20,           // <- mixumum allowed rendered suggestions
                        classname: "", // <- custom classname for this dropdown, so it could be targeted
                        enabled: 0,             // <- show suggestions on focus
                        closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
                    }
                });

            }
            updateBrandsList().catch(error => console.log(error));
        }
    }, [store.dumping, store.active]);

    let limitCountOffers = 0;
    if (store.tariff) {
        if (store.tariff.trial) {
            limitCountOffers = 500;
        } else {
            limitCountOffers = store.tariff.tariff.limitCountOffers;
        }
    }

    const lastUpdateOffers = new Date(store.lastUpdateOffers * 1000).toLocaleString('kk', {timeZone: 'Asia/Almaty'});
    const lastUpdateOrders = new Date(store.lastUpdateOrders * 1000).toLocaleString('kk', {timeZone: 'Asia/Almaty'});
    const lastUpdatePlaces = new Date(store.lastUpdatePlaces * 1000).toLocaleString('kk', {timeZone: 'Asia/Almaty'});

    return (
        <div className="card-body">
            <div
                className="alert bg-light border border-warning border-dashed d-flex flex-column flex-sm-row p-5 mb-10">

                <ul className="nav nav-pills nav-pills-custom" role="tablist">
                    <li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
                        <div className="border border-gray-900 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6">
                            <span className="fs-6 text-gray-900 fw-bold">Обновление товаров</span>
                            <div className="fs-2 fw-bold text-warning">{lastUpdateOffers}</div>
                        </div>
                    </li>
                    <li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
                        <div className="border border-gray-900 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6">
                            <span className="fs-6 text-gray-900 fw-bold">Обновление заказов</span>
                            <div className="fs-2 fw-bold text-danger">{lastUpdateOrders}</div>
                        </div>
                    </li>
                    <li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
                        <div className="border border-gray-900 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6">
                            <span className="fs-6 text-gray-900 fw-bold">Обновление места в карточке</span>
                            <div className="fs-2 fw-bold text-info">{lastUpdatePlaces}</div>
                        </div>
                    </li>
                    <li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
                        <div className="border border-gray-900 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6">
                            <span className="fs-6 text-gray-900 fw-bold">Опубликованные товары</span>
                            <div className="fs-2 fw-bold text-success text-center">{store.offer.activeCount}</div>
                        </div>
                    </li>

                </ul>

            </div>

            <form className="form">
                <div className="card-body border-top p-9">

                    <div className="row mb-6">

                        <label className="col-lg-4 col-form-label fw-semibold fs-6">Активный</label>

                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input checked={store.active}
                                       onChange={() => changeValueStore('active', !store.active)} value={'1'}
                                       className="form-check-input h-30px w-50px" name="active"
                                       type="checkbox"/>
                                <label className="form-check-label">
                                    Активный / Архивный, кабинет
                                </label>
                            </div>
                        </div>
                    </div>

                    {store.active &&
                        <>
                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">Наименование, ID</label>

                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <input onChange={(ev) => changeValueStore('name', ev.target.value)}
                                                   value={String(store.name)}
                                                   type="text" className="form-control"
                                                   aria-describedby="inputGroup-sizing-lg" name={'name'}/>
                                            <label className="form-check-label">
                                                Наименование кабинета
                                            </label>
                                        </div>
                                        <div className="col-lg-6 fv-row">
                                            <input disabled={true} type="text" name="hybrisUid"
                                                   className="form-control form-control-lg form-control-solid"
                                                   placeholder="Last name" value={store.hybrisUid}/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className={'bg-light-warning rounded'}>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Демпинг</label>

                                    <div className="col-lg-8 d-flex align-items-center">
                                        <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                            <input checked={store.dumping}
                                                   onChange={changeDumping} value={'1'}
                                                   className="form-check-input h-30px w-50px" name="dumping"
                                                   type="checkbox"/>
                                            <label className="form-check-label">Для контроля цен конкурентов, переключатель
                                                должен быть включен.</label>
                                        </div>
                                    </div>
                                </div>

                                {store.dumping &&
                                    <>
                                        <div className="card-body mb-6" id={'content_dumping'}>

                                            <div className="row mb-6">
                                                <div className="col-md-3 fv-row">
                                                    <label className="required fs-6 fw-semibold form-label mb-2">
                                                        Шаг демпинга
                                                    </label>
                                                    <div className="position-relative">
                                                        <input onChange={(ev) => changeValueStore('stepDumping', ev.target.value)}
                                                               value={store.stepDumping} type="number" className="form-control form-control-solid"
                                                               name={'step-dumping'}/>
                                                        <label className="form-check-label text-primary">
                                                            Сумма понижения от цены конкурента
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 fv-row">
                                                    <label className="fs-6 fw-semibold form-label mb-2">
                                                        Кол-во карточек (лимит)
                                                    </label>
                                                    <div className="position-relative">
                                                        <input disabled={true}
                                                               value={String(limitCountOffers)}
                                                               type="number" className="text-warning form-control form-control-solid bg-light-danger"
                                                               name={'limit-items'}/>
                                                        <label className="form-check-label text-primary">
                                                            Лимит по количеству карточек товаров доступных для демпинга в вашем
                                                            кабинете.
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-2 fv-row">
                                                    <label className="fs-6 fw-semibold form-label mb-2">
                                                        Место в карточке
                                                    </label>
                                                    <div className="position-relative">
                                                        <input onChange={(ev) => changeValueStore('dumpingPlace', ev.target.value)}
                                                               value={store.dumpingPlace}
                                                               type="number" className="form-control form-control-solid"
                                                               name={'place-dumping'}/>
                                                        <label className="form-check-label text-primary">
                                                            Желаемое место в карточке среди конкурентов.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 fv-row">
                                                    <label className="fs-6 fw-semibold form-label mb-2">
                                                        Приближаться к месту
                                                    </label>
                                                    <div className="form-check form-check-custom form-check-success form-check-solid">
                                                        <input className="form-check-input h-40px w-40px"
                                                               onChange={changeDontDumpingPlace}
                                                               checked={store.dontDumpingPlace}
                                                               name="dont-dumping-place"
                                                               type="checkbox" value="1"/>
                                                        <label className="form-check-label text-primary">
                                                            Приближаться к указанному месту но не обгонять его.
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row mb-6">

                                                <label className="col-lg-4 col-form-label fw-semibold fs-6">Список брендов</label>

                                                <div className="col-lg-8 fv-row">
                                                    <input onChange={(ev) => {
                                                        changeDumpingBrands(ev.target.value)
                                                    }} className="form-control d-flex align-items-center"
                                                           value={store.dumpingBrands}
                                                           id="brands_select"/>
                                                    <label className="form-check-label text-primary">
                                                        Укажите по каким брендам нужен демпинг цен.
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row mb-6">

                                                <label className="col-lg-4 col-form-label fw-semibold fs-6">Список ID магазинов</label>

                                                <div className="col-lg-8 fv-row">
                                                    <input
                                                        onChange={(ev) => {
                                                        changeDumpingSkipStores(ev.target.value)
                                                    }}
                                                           className="form-control d-flex align-items-center"
                                                           value={store.skipStoresIdDumping}
                                                           id="skip_stores_select"/>
                                                    <label className="form-check-label text-primary">
                                                        Укажите ID магазинов с которыми не хотите демпинговать цены.
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                }
                            </div>

                            <div className="row mb-6">

                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">Пользователь</label>

                                <div className="col-lg-8 fv-row">
                                    <input onChange={(ev) => changeValueStore('login', ev.target.value)} value={String(store.login)}
                                           type="text" className="form-control bg-light-primary"
                                           name={'user'}/>
                                    <label className="form-check-label">
                                        Пользователь доступа к личному кабинету магазина
                                    </label>
                                </div>
                            </div>

                            <div className="row mb-6">

                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">Пароль</label>

                                <div className="col-lg-8 fv-row">
                                    <input onChange={(ev) => changeValueStore('password', ev.target.value)}
                                           value={String(store.password)}
                                           type="password" className="form-control bg-light-primary"
                                           name={'password'}/>
                                    <label className="form-check-label">
                                        Пароль доступа к личному кабинету магазина
                                    </label>
                                </div>
                            </div>

                            <div className={'bg-light-danger rounded'}>
                                <div className="row mb-6">

                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Контроль остатков</label>

                                    <div className="col-lg-8 d-flex align-items-center">
                                        <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                            <input checked={store.controlOffersBalance}
                                                   onChange={changeControlOffersBalance} value={'1'}
                                                   className="form-check-input h-30px w-50px" name="offers-balance"
                                                   type="checkbox"/>
                                            <label className="form-check-label">
                                                Контроль остатков по магазину.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    }

                    {store.updateStoreData &&
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button onClick={selectCurrentStore} type="button" className="btn btn-light btn-active-light-primary me-2">
                                Отменить
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={updateDataStore}
                                    id="update_date_store">
                                <span className="indicator-label">
                                    Сохранить
                                </span>
                                <span className="indicator-progress">
                                    Пожалуйста ожидайте...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    }
                </div>
            </form>

        </div>
    );
};

const errorQueryUpdateStore = (description) => {
    window.toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toastr-top-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    };

    window.toastr.error(description, "Ошибка обновления магазина");
}

const successQueryUpdateStore = (description) => {
    window.toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toastr-top-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    };

    window.toastr.success(description, "Успешно");
}

export default StoreSettingsKaspi;