import { connect } from "react-redux";
import StoresSber from "../../../components/content_container/sber/StoresSber";


function mapStateToProps(state) {
    const { Merchant, Marketplace } = state;
    return { Merchant, Marketplace }
}

// function mapDispatchToProps(dispatch) {
//     const funcDispatch = new FUNCTION_MARKETPLACE()
//     const funcDispatchMerchant = new FUNCTION_MERCHANT()
//     return {
//         'changeMarketplaceName': (value) => dispatch(funcDispatch.changeMarketplaceName(value)),
//         'updateMerchant': (MerchantObject) => dispatch(funcDispatchMerchant.updateMerchant(MerchantObject)),
//     }
// }

export default connect(mapStateToProps)(StoresSber);