import initialState from "../initialState";
import {clearJWT_Token, setJWT_Token} from "../components/settings";

export const UserJWT =function(state = initialState.UserJWT, action) {
    switch (action.type) {
        case USER_JWT.CHANGE_TOKEN:
            if (action.token === '') {
                clearJWT_Token();
                window.location.reload();
            } else {
                setJWT_Token(action.token);
            }
            return action.token;
        default:
            return state
    }
}

export const USER_JWT = {
    CHANGE_TOKEN : "CHANGE_TOKEN",
}

export class FUNCTION_USER_JWT {
    changeToken = (token) => {
        return { type: USER_JWT.CHANGE_TOKEN , token }
    }
}