import initialState from "../initialState";

export const AdvancedSearchKaspi =function(state = initialState.AdvancedSearchKaspi, action) {
    switch (action.type) {
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_STRING:
            return {
                ...state,
                searchString: action.searchString,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_BRANDS:
            return {
                ...state,
                searchBrands: action.searchBrands,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_OFFER_STATUS:
            return {
                ...state,
                offerStatus: action.offerStatus,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_PLACE:
            return {
                ...state,
                place: action.place,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_NOT_MIN_PRICE:
            return {
                ...state,
                notMinPrice: action.notMinPrice,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_NOT_MAX_PRICE:
            return {
                ...state,
                notMaxPrice: action.notMaxPrice,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_DUMPING:
            const dumping = action.dumping === '' ? undefined : action.dumping !== '0'
            return {
                ...state,
                dumping: dumping,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_SEARCH_CONTROL_BALANCE:
            const controlBalance = action.controlBalance === '' ? undefined : action.controlBalance !== '0'
            return {
                ...state,
                controlBalance: controlBalance,
                resetPagination: true
            }
        case SEARCH_OFFERS_KASPI.CHANGE_RESET_PAGINATION:
            return {
                ...state,
                resetPagination: false
            }
        case SEARCH_OFFERS_KASPI.CHANGE_CLEAN_SEARCH:
            return {
                ...initialState.AdvancedSearchKaspi,
            }
        default:
            return state
    }
}

export const SEARCH_OFFERS_KASPI = {
    CHANGE_SEARCH_STRING : "CHANGE_SEARCH_STRING",
    CHANGE_SEARCH_BRANDS : "CHANGE_SEARCH_BRANDS",
    CHANGE_SEARCH_OFFER_STATUS : "CHANGE_SEARCH_OFFER_STATUS",
    CHANGE_SEARCH_PLACE : "CHANGE_SEARCH_PLACE",
    CHANGE_SEARCH_NOT_MIN_PRICE : "CHANGE_SEARCH_NOT_MIN_PRICE",
    CHANGE_SEARCH_NOT_MAX_PRICE : "CHANGE_SEARCH_NOT_MAX_PRICE",
    CHANGE_SEARCH_DUMPING : "CHANGE_SEARCH_DUMPING",
    CHANGE_SEARCH_CONTROL_BALANCE : "CHANGE_SEARCH_CONTROL_BALANCE",
    CHANGE_RESET_PAGINATION : "CHANGE_RESET_PAGINATION",
    CHANGE_CLEAN_SEARCH : "CHANGE_CLEAN_SEARCH",
}

export class FUNCTION_SEARCH_OFFERS_KASPI {
    changeSearchString = (searchString) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_STRING , searchString}
    }
    changeSearchBrands = (searchBrands) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_BRANDS , searchBrands}
    }
    changeSearchOfferStatus = (offerStatus) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_OFFER_STATUS , offerStatus}
    }
    changeSearchPlace = (place) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_PLACE , place}
    }
    changeSearchNotMinPrice = (notMinPrice) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_NOT_MIN_PRICE , notMinPrice}
    }
    changeSearchNotMaxPrice = (notMaxPrice) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_NOT_MAX_PRICE , notMaxPrice}
    }
    changeSearchDumping = (dumping) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_DUMPING , dumping}
    }
    changeSearchControlBalance = (controlBalance) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_SEARCH_CONTROL_BALANCE , controlBalance}
    }
    changeResetPagination = (resetPagination) => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_RESET_PAGINATION , resetPagination}
    }
    changeCleanSearch = () => {
        return { type: SEARCH_OFFERS_KASPI.CHANGE_CLEAN_SEARCH }
    }
}