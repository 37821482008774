import initialState from "../initialState";

export const AuthenticationTypePage =function(state = initialState.AuthenticationTypePage, action) {
    switch (action.type) {
        case AUTH_TYPE_PAGE.CHANGE_TYPE_PAGE:
            return action.value;
        default:
            return state
    }
}

export const AUTH_TYPE_PAGE = {
    CHANGE_TYPE_PAGE : "CHANGE_TYPE_PAGE",
}

export class FUNCTION_AUTH_TYPE_PAGE {
    changeTypePage = (value) => {
        return { type: AUTH_TYPE_PAGE.CHANGE_TYPE_PAGE , value }
    }
}