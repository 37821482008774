import { connect } from "react-redux";
import StoreOffersKaspi from "../../../../../components/content_container/kaspi/detail_store/store_offers/StoreOffersKaspi";
import {FUNCTION_EDIT_OFFER_FIELD} from "../../../../../reducers/EditOfferField";


function mapStateToProps(state) {
    const { Pagination, DataOffers, SelectedStore } = state;
    return { Pagination, DataOffers, SelectedStore }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_EDIT_OFFER_FIELD()
    return {
        'changeFieldOffer': (offer) => dispatch(funcDispatch.changeFieldOffer(offer)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(StoreOffersKaspi);