import React from 'react';
import AdvancedSearchKaspi
    from "../../../../../conteiners/content_container/kaspi/detail_store/store_offers/AdvancedSearchKaspi";
import StoreOffersKaspi
    from "../../../../../conteiners/content_container/kaspi/detail_store/store_offers/StoreOffersKaspi";

const OffersKaspi = (props) => {
    return (
        <div className="card card-flush">
            <AdvancedSearchKaspi />
            <StoreOffersKaspi />
        </div>
    );
};

export default OffersKaspi;