import React from 'react';

const Footer = (props) => {
    return (
        <div id="mp_app_footer" className="app-footer">
            <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2022&copy;</span>
                    <a href="#"
                       className="text-gray-800 text-hover-primary">TOP SALE GURU</a>
                </div>

                <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                        <a href="#" className="menu-link px-2">О нас</a>
                    </li>
                    <li className="menu-item">
                        <a href="#" className="menu-link px-2">Помощь</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;