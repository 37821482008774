import ScrollTop from "./ScrollTop";
import PageContent from "../conteiners/PageContent";
import {useEffect, useState} from "react";
import {getHeaderData, getJWT_Token, URL_DATA_BASE} from "./settings";
import ChatDrawer from "./drawers/chat_drawer/ChatDrawer";
import {Code} from "react-content-loader";

function App(props) {

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const updateMarketplaceList = async () => {
            try {
                const response = await fetch(`${URL_DATA_BASE}/marketplace/all/`);
                if (response.status === 200) {
                    const body = await response.json();
                    if (body?.length) {
                        props.changeMarketplaceList(body);
                    }
                } else {
                    props.changeMarketplaceList([]);
                }
            } catch (error) {
                props.changeMarketplaceList([]);
                console.log(error);
            }
        }
        const localToken = getJWT_Token();
        const checkToken = async () => {
            const response = await fetch(`${URL_DATA_BASE}/user/info`,
                getHeaderData('GET', null, localToken));
            if (response.status === 200) {
                const body = await response.json();
                if (body?.detail) {
                    props.changeToken('');
                } else {
                    props.changeToken(body.JWT);
                }
            } else {
                props.changeToken('');
            }
        }

        updateMarketplaceList().catch(error => console.log(error));

        if (!props.UserJWT) {
            setLoading(true);
            if (localToken) {
                checkToken().catch(error => {
                    console.log(error);
                    props.changeToken('');
                });
            }
            setTimeout(setLoading, 1200, false)
        }

    }, [])

    return (
        <>
            {loading ? <Code /> : <PageContent />}

            <ChatDrawer />
            {/*ДОПОЛНИТЕЛЬНЫЕ БЛОКИ МОДАЛЬНЫЕ ДРАВЕРЫ*/}
            <ScrollTop />
        </>

    )

}

export default App
