import React, {useEffect} from 'react';
import {KTSVG} from "../KTSVG";
import {blockInformationNoStore} from "../settings";

const ToolbarMarket = (props) => {

    useEffect(() => {
        if (props.Marketplace.stores.length) {
            if (props.SelectedStore?.id !== undefined){
                onClickStore(props.SelectedStore?.id);
            } else {
                onClickStore(props.Marketplace.stores[0].id);
            }
        }
    }, [props.Marketplace.stores])

    function onClickStore(idStore) {
        props.changeStore(props.Marketplace.stores.filter(
            elFilter => elFilter.id === idStore
        )[0], props.Marketplace.name);
        props.changeDetailName('settings');
        props.setFirstState(0);
    }

    return (
        <>
            <div className="card card-flush mb-xxl-10 mb-5">
                <div className="card-body">

                    <div className="d-flex flex-stack flex-wrap gap-2 mt-n1">

                        <div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2">
                            <h1 className="d-flex text-dark fw-bold m-0 fs-3">Мои магазины</h1>
                        </div>

                        <ul className="nav nav-pills nav-pills-custom mb-3">

                            {props.Marketplace.stores.map((el, ind) => {
                                return (
                                    <li key={ind}
                                        onClick={() => onClickStore(el.id)}
                                        className="nav-item mb-3 me-3 me-lg-6">
                                        <a className={`nav-link d-flex justify-content-between flex-column flex-center overflow-hidden ${(!ind) ? 'active' : ''} w-150px h-60px py-4`}
                                           data-bs-toggle="pill" href={`#_${el.id}`}>
                                            {/*<div className="nav-icon">*/}
                                            {/*    <img alt="" src={`assets/media/icons/${props.Marketplace.name}.png`} className=""/>*/}
                                            {/*</div>*/}
                                            <span className="nav-text text-gray-700 fw-bold fs-6 lh-1">{el.name}</span>
                                            <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        </a>
                                    </li>
                                )
                            })
                            }

                            <li className="nav-item mb-0">
                                <a className="nav-link d-flex flex-center overflow-hidden w-100px h-60px"
                                   data-bs-toggle="modal"
                                   data-bs-target="#mp_modal_create_store" href="#">
                                    <div className="nav-icon">
                                        <KTSVG path={'assets/media/icons/duotune/general/gen035.svg'}
                                               className={'svg-icon-2hx svg-icon-gray-400'}/>
                                    </div>
                                    <span
                                        className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                </a>
                            </li>

                        </ul>

                        <div className="d-flex align-items-center py-2"></div>

                    </div>
                </div>
            </div>

            {!props.Marketplace.stores.length && blockInformationNoStore()}
        </>
    );
};

export default ToolbarMarket;