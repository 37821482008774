import { connect } from "react-redux";
import {FUNCTION_MARKETPLACE} from "../../reducers/Marketplace";
import ToolbarHeaderStore from "../../components/content_container/ToolbarHeaderStore";


function mapStateToProps(state) {
    const { Marketplace } = state;
    return { Marketplace }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE()
    return {
        'changeDetailName': (value) => dispatch(funcDispatch.changeDetailName(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarHeaderStore);