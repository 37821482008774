import { connect } from "react-redux";
import SignIn from "../../../components/authentication/sign_in/SignIn";
import {FUNCTION_AUTH_TYPE_PAGE} from "../../../reducers/AuthenticationTypePage";
import {FUNCTION_USER_JWT} from "../../../reducers/UserJWT";


function mapStateToProps(state) {
    const { AuthenticationTypePage } = state;
    return { AuthenticationTypePage }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_AUTH_TYPE_PAGE()
    const funcDispatchUserJWT = new FUNCTION_USER_JWT()
    return {
        'changeToken': (token) => dispatch(funcDispatchUserJWT.changeToken(token)),
        'changeTypePage': (value) => dispatch(funcDispatch.changeTypePage(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);