import initialState from "../initialState";

export const Marketplace = function (state = initialState.Marketplace, action) {
    switch (action.type) {
        case MARKETPLACE.CHANGE_NAME:
            return {
                ...initialState.Marketplace,
                name: action.value,
            }
        case MARKETPLACE.CHANGE_STORES:
            return {
                ...state,
                stores: action.stores
            }
        case MARKETPLACE.CHANGE_DETAIL_NAME:
            return {
                ...state,
                detailName: action.value
            }






        case MARKETPLACE.CHANGE_DETAIL_INFORMATION_DATA_STORE:
            return {
                ...state,
                detailInformation: {
                    ...state.detailInformation,
                    dataStore: action.dataStore
                }
            }
        case MARKETPLACE.CHANGE_DETAIL_INFORMATION_DATA_OFFERS:
            return {
                ...state,
                detailInformation: {
                    ...state.detailInformation,
                    dataOffers: action.dataOffers
                }
            }
        case MARKETPLACE.CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA:
            return {
                ...state,
                detailInformation: {
                    ...state.detailInformation,
                    search: {
                        ...initialState.Marketplace.detailInformation.search,
                        searchData: action.searchData
                    }
                }
            }
        case MARKETPLACE.CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA_STRING:
            return {
                ...state,
                detailInformation: {
                    ...state.detailInformation,
                    search: {
                        ...state.detailInformation.search,
                        searchDataString: action.value
                    }
                }
            }
        case MARKETPLACE.CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_STRING:
            return {
                ...state,
                detailInformation: {
                    ...state.detailInformation,
                    search: {
                        ...state.detailInformation.search,
                        searchString: action.value,
                    }
                }
            }
        default:
            return state
    }
}

export const MARKETPLACE = {
    CHANGE_NAME : "CHANGE_NAME",
    CHANGE_STORES : "CHANGE_STORES",
    CHANGE_DETAIL_NAME : "CHANGE_DETAIL_NAME",



    CHANGE_MARKETPLACE_STORE_LIST : "CHANGE_MARKETPLACE_STORE_LIST",

    CHANGE_DETAIL_INFORMATION_DATA_OFFERS : "CHANGE_DETAIL_INFORMATION_DATA_OFFERS",
    CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA : "CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA",
    CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_STRING : "CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_STRING",
    CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA_STRING : "CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA_STRING",
}

export class FUNCTION_MARKETPLACE {
    changeMarketplaceName = (value) => {
        return { type: MARKETPLACE.CHANGE_NAME, value }
    }
    changeMarketplaceStores = (stores) => {
        return { type: MARKETPLACE.CHANGE_STORES, stores }
    }
    changeDetailName = (value) => {
        return { type: MARKETPLACE.CHANGE_DETAIL_NAME, value }
    }



    changeDetailInformationDataStore = (dataStore) => {
        return { type: MARKETPLACE.CHANGE_DETAIL_INFORMATION_DATA_STORE, dataStore }
    }
    changeDetailInformationDataOffers = (dataOffers) => {
        return { type: MARKETPLACE.CHANGE_DETAIL_INFORMATION_DATA_OFFERS, dataOffers }
    }
    changeDetailInformationSearchSearchString = (value) => {
        return { type: MARKETPLACE.CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_STRING, value }
    }
    changeDetailInformationSearchSearchDataString = (value) => {
        return { type: MARKETPLACE.CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA_STRING, value }
    }
    changeDetailInformationSearchSearchData = (searchData) => {
        return { type: MARKETPLACE.CHANGE_DETAIL_INFORMATION_SEARCH_SEARCH_DATA, searchData }
    }

}


export const MarketplaceList = function (state = initialState.MarketplaceList, action) {
    switch (action.type) {
        case MARKETPLACE_LIST.CHANGE_MARKETPLACE_LIST:
            return action.marketplaceList
        default:
            return state
    }
}

export const MARKETPLACE_LIST = {
    CHANGE_MARKETPLACE_LIST : 'CHANGE_MARKETPLACE_LIST'
}

export class FUNCTION_MARKETPLACE_LIST {
    changeMarketplaceList = (marketplaceList) => {
        return { type: MARKETPLACE_LIST.CHANGE_MARKETPLACE_LIST, marketplaceList }
    }
}