import React, {useEffect} from 'react';
import Pagination from "../../../../../conteiners/Pagination";
import {formatSumma} from "../../../../settings";
import {Code} from "react-content-loader";
import {KTSVG} from "../../../../KTSVG";
import EditOfferModal from "../../../../../conteiners/content_container/kaspi/detail_store/store_offers/EditOfferModal";

const StoreOffersKaspi = (props) => {

    useEffect(()=> {
        window.KTMenu.createInstances();
    }, [props.DataOffers])

    // console.log(props)

    const setJSXInformationUpdateStore = () => {

        const totalCount = props.SelectedStore.offer.activeCount + props.SelectedStore.offer.archiveCount;
        return (
            <div className="alert alert-dismissible bg-danger d-flex flex-column flex-sm-row p-5 mb-10">

                <KTSVG path={'assets/media/icons/duotune/general/gen007.svg'}
                       className={'svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0'} />

                <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                    <h4 className="mb-2 light">
                        Производится обновление данных по кабинету ({props.SelectedStore.name})
                    </h4>
                    <span>В данный момент идёт обновление данных по кабинету. Как обновление завершится, данная информационная надпись исчезнет.</span>
                    <div className="separator separator-dashed border-primary my-3"></div>
                    <span>Всего загружено карточек товаров {props.Pagination.totalCountItems} из {totalCount} </span>

                </div>

            </div>
        )
    }

    if (props.DataOffers.length === 0) {
        if (props.SelectedStore.updateStore) {
            return (
                <div className={'card-body pt-0'}>
                    {setJSXInformationUpdateStore()}
                    <Code />
                </div>
            )
        }
        return <div className={'card-body pt-0'}><Code /></div>
    }

    return (
        <div className="card-body pt-0">
            {props.SelectedStore.updateStore && setJSXInformationUpdateStore()}

            <Pagination />

            <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5" id={"mp_ecommerce_products_table"}>
                    <thead>
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th className="w-10px pe-2">
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input className="form-check-input" type="checkbox" value="1" data-kt-check="true"
                                       data-kt-check-target="#mp_ecommerce_products_table .form-check-input"/>
                            </div>
                        </th>
                        <th className="min-w-200px">Товар</th>
                        <th className="min-w-25pxx">Место</th>
                        <th className="min-w-25pxx">Демпинг</th>
                        <th className="text-center min-w-50px">Цена, тенге</th>
                        <th className="text-center min-w-70px">Наличие в магазинах</th>
                        <th className="text-center min-w-50px">Статус</th>
                        <th className="text-end min-w-70px">Действия</th>
                    </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">

                    <ItemsKaspi
                        currentItems={props.DataOffers}
                        changeFieldOffer={props.changeFieldOffer}
                    />

                    </tbody>
                </table>
            </div>

            <Pagination />

            <EditOfferModal />

        </div>
    );
};

function ItemsKaspi({currentItems, changeFieldOffer}) {

    return (
        <>
            {currentItems.map((el, ind) => {
                const isUpdateStatus = el.status === 'UPDATE';
                const isArchive = el.offerStatus === 'ARCHIVE';
                const isProcessing = el.offerStatus === 'PROCESSING';
                let cityInfoSet = new Set();
                el.cities.forEach( elCity => {
                    if (elCity.warehouses) {
                        elCity.warehouses.forEach( elPickup => {
                            if (elPickup.status === "ACTIVE") {
                                cityInfoSet.add(elPickup.displayName)
                            }
                        } )
                    }
                } )

                cityInfoSet = Array.from(cityInfoSet).join(', ');
                const place = (!el.place) ? '?' : el.place;
                let classNamePlace = 'badge-danger'
                if (typeof place !== 'string'){
                    if (place === 1){
                        classNamePlace = 'badge-success'
                    } else if (place === 2) {
                        classNamePlace = 'badge-info'
                    } else {
                        classNamePlace = 'badge-warning'
                    }
                }
                const activeDumpingOffer = el.dumping;
                let priceMin = 0;
                let priceMax = 0;
                if (el?.cities?.length) {
                    priceMin = el.cities[0].priceMin;
                    priceMax = el.cities[0].priceMax;
                }
                return (
                    <tr key={ind} className={`${isArchive ? 'bg-hover-light-danger bg-light-warning' : 'bg-hover-light-success'}`}>
                        <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="1"/>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <a target="_blank"
                                   className="symbol symbol-75px">
                                    <span className="symbol-label"
                                          style={{backgroundImage: `url(${el.masterProduct.primaryImage.small})`}}></span>
                                </a>
                                <div className="ms-5">
                                    <a href={el.masterProduct.productUrl} target="_blank"
                                       className="text-gray-800 text-hover-primary fs-5 fw-bold"
                                       data-kt-ecommerce-product-filter="product_name">{el.masterProduct.name}</a>
                                    <span className="text-muted fw-semibold d-block fs-7">{el.name}</span>
                                    <span className="text-dark fw-bolder d-block fs-5">{el.sku}</span>

                                </div>
                            </div>
                        </td>

                        <td className="text-center pe-0 fs-3">
                            <span className={`badge badge-square ${classNamePlace} badge-circle badge-lg fs-2`}>{place}</span>
                        </td>

                        <td className="text-center pe-0 fs-3">
                            <span className={`badge badge-light-${activeDumpingOffer ? "success" : "danger"} fs-7 fw-bold`}>{activeDumpingOffer ? 'включен' : "выключен"}</span>
                        </td>

                        <td className="text-center pe-0 fs-3">
                            <div className="ms-5">
                                <span className="text-dark fw-bolder d-block fs-5">{formatSumma(el.priceMin)}</span>
                                <span className="text-muted fw-semibold d-block fs-7">min: {formatSumma(priceMin)}</span>
                                <span className="text-muted fw-semibold d-block fs-7">max: {formatSumma(priceMax)}</span>
                            </div>

                        </td>

                        <td className="text-center pe-0">
                            {cityInfoSet}
                        </td>

                        <td className="text-center pe-0">
                            <div className={`badge badge-light-${isArchive ? 'danger' : 'success'} fs-5`}>
                                {isProcessing && <KTSVG
                                    path={'assets/media/icons/duotune/general/gen012.svg'}
                                    className={'svg-icon-3x svg-icon-danger mb-2'}
                                />}
                                {isArchive ? 'Снят с продажи' : 'В продаже до'} {new Date(el.expireDate).toLocaleDateString()}
                            </div>
                        </td>

                        <td className="text-end">
                            <a href="#"
                               onClick={()=> changeFieldOffer(el)}
                               data-bs-toggle="modal"
                               data-bs-target="#mp_modal_edit_offer"
                               className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                <KTSVG path={'assets/media/icons/duotune/art/art005.svg'}
                                       className={'svg-icon-3'} />
                            </a>
                        </td>


                    </tr>
                )
            })}
        </>
    )
}

export default StoreOffersKaspi;