import { connect } from "react-redux";
import SidebarNav from "../../../components/app_sidebar/sidebar_nav/SidebarNav";
import {FUNCTION_MARKETPLACE} from "../../../reducers/Marketplace";
import {FUNCTION_SELECTED_STORE} from "../../../reducers/SelectedStore";


function mapStateToProps(state) {
    const { UserJWT, MarketplaceList, SelectedStore } = state;
    return { UserJWT, MarketplaceList, SelectedStore}
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE();
    const funcDispatchSelectedStore = new FUNCTION_SELECTED_STORE();
    return {
        'changeMarketplaceName': (value) => dispatch(funcDispatch.changeMarketplaceName(value)),
        'changeMarketplaceStores': (stores) => dispatch(funcDispatch.changeMarketplaceStores(stores)),
        'changeStore': (store, name) => dispatch(funcDispatchSelectedStore.changeStore(store, name)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav);