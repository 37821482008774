import { connect } from "react-redux";
import SidebarLogo from "../../../components/app_sidebar/sidebar_logo/SidebarLogo";
import {FUNCTION_USER_JWT} from "../../../reducers/UserJWT";

function mapStateToProps(state) {
    const { UserJWT } = state;
    return { UserJWT }
}

function mapDispatchToProps(dispatch) {
    const funcDispatchUserJWT = new FUNCTION_USER_JWT();
    return {
        'changeToken': (token) => dispatch(funcDispatchUserJWT.changeToken(token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLogo);