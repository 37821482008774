import { connect } from "react-redux";
import ToolbarMarket from "../../components/content_container/ToolbarMarket";
import {FUNCTION_MARKETPLACE} from "../../reducers/Marketplace";
import {FUNCTION_SELECTED_STORE} from "../../reducers/SelectedStore";
import {FUNCTION_PAGINATION} from "../../reducers/Pagination";


function mapStateToProps(state) {
    const { Marketplace, SelectedStore } = state;
    return { Marketplace, SelectedStore }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE();
    const funcDispatchPagination = new FUNCTION_PAGINATION();
    const funcDispatchSelectedStore = new FUNCTION_SELECTED_STORE();
    return {
        'setFirstState': (value) => dispatch(funcDispatchPagination.setFirstState(value)),
        'changeDetailName': (value) => dispatch(funcDispatch.changeDetailName(value)),
        'changeStore': (store, name) => dispatch(funcDispatchSelectedStore.changeStore(store, name)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarMarket);