import React from 'react';
import StoreSettingsSber
    from "../../../../conteiners/content_container/sber/detail_store/store_settings/StoreSettingsSber";

const DetailStoreSber = ({ action }) => {

    switch (action) {
        case 'settings':
            return <StoreSettingsSber />
        case 'offers':
        default:
            return null
    }
};

export default DetailStoreSber;