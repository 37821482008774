import React, {useEffect} from 'react';
import {KTSVG} from "../../KTSVG";

const SidebarFooter = (props) => {

    useEffect(() => {
        window.KTMenu.createInstances();
    }, [])

    return (
        <div className="flex-column-auto d-flex flex-center px-4 px-lg-8 py-3 py-lg-8" id="mp_app_sidebar_footer">

            <div className="app-footer-item me-6">
                <div
                    className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-start">
                    <i className="fonticon-layers fs-2"></i>
                </div>
                <div className="menu menu-sub menu-sub-dropdown menu-column w-100 w-sm-350px" data-kt-menu="true">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">My Apps</div>

                            <div className="card-toolbar">
                                <button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n3"
                                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                        data-kt-menu-placement="bottom-end">
                                    <KTSVG path={'assets/media/icons/duotune/general/gen019.svg'}
                                           className={'svg-icon-2'}/>
                                </button>
                                <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                    data-kt-menu="true">
                                    <div className="menu-item px-3">
                                        <div
                                            className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments
                                        </div>
                                    </div>

                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Create Invoice</a>
                                    </div>

                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link flex-stack px-3">Create Payment
                                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                               title="Specify a target name for future usage and reference"></i></a>
                                    </div>

                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Generate Bill</a>
                                    </div>

                                    <div className="menu-item px-3" data-kt-menu-trigger="hover"
                                         data-kt-menu-placement="right-end">
                                        <a href="#" className="menu-link px-3">
                                            <span className="menu-title">Subscription</span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3">Plans</a>
                                            </div>

                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3">Billing</a>
                                            </div>

                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3">Statements</a>
                                            </div>

                                            <div className="separator my-2"></div>

                                            <div className="menu-item px-3">
                                                <div className="menu-content px-3">
                                                    <label
                                                        className="form-check form-switch form-check-custom form-check-solid">
                                                        <input className="form-check-input w-30px h-20px"
                                                               type="checkbox" value="1"
                                                               name="notifications"/>

                                                        <span
                                                            className="form-check-label text-muted fs-6">Recuring</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="menu-item px-3 my-1">
                                        <a href="#" className="menu-link px-3">Settings</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body py-5">
                            <div className="mh-450px scroll-y me-n5 pe-5">
                                <div className="row g-2">
                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/amazon.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">AWS</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/angular-icon-1.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">AngularJS</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/atica.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Atica</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/beats-electronics.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Music</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/codeigniter.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Codeigniter</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/bootstrap-4.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Bootstrap</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/google-tag-manager.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">GTM</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/disqus.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Disqus</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/dribbble-icon-1.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Dribble</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/google-play-store.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Play Store</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/google-podcasts.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Podcasts</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/figma-1.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Figma</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/github.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Github</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/gitlab.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Gitlab</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/instagram-2-1.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Instagram</span>
                                        </a>
                                    </div>

                                    <div className="col-4">
                                        <a href="#"
                                           className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                                            <img src="assets/media/svg/brand-logos/pinterest-p.svg"
                                                 className="w-25px h-25px mb-2" alt=""/>
                                            <span className="fw-semibold">Pinterest</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="app-footer-item me-6">
                <div
                    className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-start">
                    <i className="fonticon-alarm fs-2"></i>
                </div>
                <div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px" data-kt-menu="true">
                    <div className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10"
                         style={{backgroundImage: "url('assets/media/misc/menu-header-bg.jpg')"}}
                    >
                        <h3 className="text-white fw-semibold mb-3">Quick Links</h3>

                        <span className="badge bg-primary py-2 px-3">25 pending tasks</span>
                    </div>

                    <div className="row g-0">
                        <div className="col-6">
                            <a href="#"
                               className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">

                                <KTSVG path={'assets/media/icons/duotune/finance/fin009.svg'}
                                       className={'svg-icon-3x svg-icon-primary mb-2'}/>

                                <span className="fs-5 fw-semibold text-gray-800 mb-0">Accounting</span>
                                <span className="fs-7 text-gray-400">eCommerce</span>
                            </a>
                        </div>

                        <div className="col-6">
                            <a href="#"
                               className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom">

                                <KTSVG path={'assets/media/icons/duotune/communication/com010.svg'}
                                       className={'svg-icon-3x svg-icon-primary mb-2'}/>

                                <span className="fs-5 fw-semibold text-gray-800 mb-0">Administration</span>
                                <span className="fs-7 text-gray-400">Console</span>
                            </a>
                        </div>

                        <div className="col-6">
                            <a href="#"
                               className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end">

                                <KTSVG path={'assets/media/icons/duotune/abstract/abs042.svg'}
                                       className={'svg-icon-3x svg-icon-primary mb-2'}/>

                                <span className="fs-5 fw-semibold text-gray-800 mb-0">Projects</span>
                                <span className="fs-7 text-gray-400">Pending Tasks</span>
                            </a>
                        </div>

                        <div className="col-6">
                            <a href="#"
                               className="d-flex flex-column flex-center h-100 p-6 bg-hover-light">
                                <KTSVG path={'assets/media/icons/duotune/finance/fin006.svg'}
                                       className={'svg-icon-3x svg-icon-primary mb-2'}/>

                                <span className="fs-5 fw-semibold text-gray-800 mb-0">Customers</span>
                                <span className="fs-7 text-gray-400">Latest cases</span>
                            </a>
                        </div>
                    </div>

                    <div className="py-2 text-center border-top">
                        <a href="#"
                           className="btn btn-color-gray-600 btn-active-color-primary">View All
                            <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}
                                   className={'svg-icon-5'}/>
                        </a>
                    </div>
                </div>
            </div>

            <div className="app-footer-item">
                <div
                    className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-start">
                    <i className="fonticon-settings-1 fs-2"></i>
                </div>
            </div>

        </div>
    );
};

export default SidebarFooter;