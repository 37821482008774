import React from 'react';
import ToolbarHeaderStore from "../../../conteiners/content_container/ToolbarHeaderStore";
import DetailStoreKaspi from "../../../conteiners/content_container/kaspi/detail_store/DetailStoreKaspi";

const StoresKaspi = (props) => {

    if (Object.keys(props.SelectedStore).length < 2) return null;

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card card-flush">

                    <ToolbarHeaderStore />

                    <DetailStoreKaspi />

                </div>
            </div>
        </div>
    );
};

export default StoresKaspi;