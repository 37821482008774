import React from 'react';
import ToolbarMarket from "../../../conteiners/content_container/ToolbarMarket";
import ModalCreateStore from "../../../conteiners/modal_window/ModalCreateStore";
import StoresSber from "../../../conteiners/content_container/sber/StoresSber";

const ContentSber = (props) => {

    const updateMerchantData = () => {
        const url = `${props.Marketplace.urlLink}/user/info`;
        // updateUserData(
        //     url,
        //     ,
        //     props.updateMerchant
        //     );
    }

    const dataFieldModalStoreCreate = {
        login: {title: 'Логин', type: "email", placeholder: 'Укажите логин', required: true},
        password: {title: 'Пароль', type: 'password', placeholder: 'Укажите пароль', required: true},
        userId: {type: 'text', hidden: true, value: props.Merchant.id},
        name: {type: 'text', hidden: true, value: props.Marketplace.name},
    }

    return (
        <>
            <div id="kt_content_container" className="container-custom container-xxl d-flex flex-column-fluid">
                <div className="content d-flex flex-row flex-row-fluid" id="kt_content">
                    <div className="d-flex flex-column flex-row-fluid">
                        <ToolbarMarket storeList={props.Merchant.storesSber}/>
                        <StoresSber />
                    </div>
                </div>
            </div>

            <ModalCreateStore
                data={dataFieldModalStoreCreate}
                updateMerchantData={updateMerchantData}
            />
        </>
    );
};

export default ContentSber;