import initialState from "../initialState";
import {showPageLoadingTimeout} from "../components/settings";

export const Pagination =function(state = initialState.Pagination, action) {
    switch (action.type) {
        case PAGINATION.SET_FIRST_STATE:
            return {
                ...initialState.Pagination,
                totalCountItems: action.value,
                itemsPerPage: state.itemsPerPage
            }
        case PAGINATION.SET_ITEM_PER_PAGE:
            return {
                ...state,
                itemsPerPage: action.value
            }
        case PAGINATION.SET_TOTAL_COUNT_ITEMS:
            return {
                ...state,
                totalCountItems: action.value
            }
        case PAGINATION.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.value
            }
        default:
            return state
    }
}

export const PAGINATION = {
    SET_ITEM_PER_PAGE : "SET_ITEM_PER_PAGE",
    SET_TOTAL_COUNT_ITEMS : "SET_TOTAL_COUNT_ITEMS",
    SET_CURRENT_PAGE : "SET_CURRENT_PAGE",
    SET_FIRST_STATE : "SET_FIRST_STATE",
}

export class FUNCTION_PAGINATION {
    setFirstState = (value) => {
        return { type: PAGINATION.SET_FIRST_STATE , value}
    }
    setItemsPerPage = (value) => {
        return { type: PAGINATION.SET_ITEM_PER_PAGE , value}
    }
    setTotalCountItems = (value) => {
        return { type: PAGINATION.SET_TOTAL_COUNT_ITEMS , value}
    }
    setCurrentPage = (value) => {
        showPageLoadingTimeout(400);
        return { type: PAGINATION.SET_CURRENT_PAGE , value}
    }
}