import { connect } from "react-redux";
import StoreSettingsKaspi from "../../../../../components/content_container/kaspi/detail_store/store_settings/StoreSettingsKaspi";
import {FUNCTION_MARKETPLACE} from "../../../../../reducers/Marketplace";

function mapStateToProps(state) {
    const { UserJWT, SelectedStore } = state;
    return { UserJWT, SelectedStore }
}

function mapDispatchToProps(dispatch) {
    const funcDispatchMarketplace = new FUNCTION_MARKETPLACE();
    return {
        'changeMarketplaceStores': (stores) => dispatch(funcDispatchMarketplace.changeMarketplaceStores(stores)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSettingsKaspi);