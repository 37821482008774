import React from 'react';

const ToolbarHeaderStore = (props) => {

    const selectButton = (idName) => {
        const active = document.querySelector('label.active');
        active.classList.remove('active');
        document.querySelector(`#${idName}`).classList.add('active');
        props.changeDetailName(idName);
    }

    return (
        <div className="card-header pt-8">
            <div className="card-toolbar">

                <div className="btn-group w-100 w-lg-50" data-kt-buttons="true"
                     data-kt-buttons-target="[data-kt-button]">
                    <label className={`btn btn-outline btn-color-muted btn-active-success ${
                        (props.Marketplace.detailName === 'settings') ? 'active' : ''
                    }`}
                           data-kt-button="true"
                           id={'settings'}
                           onClick={() => selectButton('settings')}
                    >
                        <input className="btn-check" type="radio" name="settings"/>
                        Настройки
                    </label>

                    <label className={`btn btn-outline btn-color-muted btn-active-success ${
                        (props.Marketplace.detailName === 'offers') ? 'active' : ''
                    }`}
                           data-kt-button="true"
                           id={'offers'}
                           onClick={() => selectButton('offers')}
                    >
                        <input className="btn-check" type="radio" name="offers"/>
                        Товары
                    </label>

                    <label className={`btn btn-outline btn-color-muted btn-active-success ${
                        (props.Marketplace.detailName === 'invoice') ? 'active' : ''
                    }`}
                           data-kt-button="true"
                           id={'invoice'}
                           onClick={() => selectButton('invoice')}
                    >
                        <input className="btn-check" type="radio" name="invoice"/>
                        Заказы
                    </label>

                </div>

            </div>
        </div>
    );
};

export default ToolbarHeaderStore;