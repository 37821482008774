import { createStore, combineReducers } from 'redux'
import {Marketplace, MarketplaceList} from "./reducers/Marketplace";
import {Pagination} from "./reducers/Pagination";
import {SelectedStore} from "./reducers/SelectedStore";
import {EditOfferField} from "./reducers/EditOfferField";
import {UserJWT} from "./reducers/UserJWT";
import {AuthenticationTypePage} from "./reducers/AuthenticationTypePage";
import {AdvancedSearchKaspi} from "./reducers/AdvancedSearchKaspi";
import {DataOffers} from "./reducers/DataOffers";

export const storeFabric = (initialState) => {
    return createStore(
        combineReducers(
            {
                Marketplace,
                MarketplaceList,
                Pagination,
                SelectedStore,
                EditOfferField,
                UserJWT,
                AuthenticationTypePage,
                AdvancedSearchKaspi,
                DataOffers,
            }
        )
        ,
        initialState
    )
}