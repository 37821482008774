import React from 'react';
import {
    expireDateTariff, getHeaderData,
    showPageLoadingTimeout,
    URL_DATA_BASE
} from "../../settings";

const SidebarNav = (props) => {

    const selectActiveMarketplace = (ev, marketplaceName) => {
        const allElemMarketActive = document.querySelectorAll("a.border-dashed");
        allElemMarketActive.forEach(activeEl => {
            activeEl.classList.remove('active');
            activeEl.classList.remove('border-primary');
            activeEl.classList.remove('border-dashed');
            activeEl.classList.add('border-gray-200');
        })
        const currentActiveEl = document.querySelector(`[market="${marketplaceName}"]`);
        currentActiveEl.classList.add('active');
        currentActiveEl.classList.add('border-primary');
        currentActiveEl.classList.add('border-dashed');
        currentActiveEl.classList.remove('border-gray-200');

        props.changeMarketplaceName(marketplaceName);
        fetch(`${URL_DATA_BASE}/${marketplaceName}/store/list`,
            getHeaderData('GET', null, props.UserJWT))
                .then(response => response.json())
                .then(body => {
                    if (!body?.detail) {
                        props.changeMarketplaceStores(body);
                    }
                }).catch(error => {
                    props.changeMarketplaceStores([]);
                    console.log(error);
        })
        showPageLoadingTimeout(1000);

        props.changeStore({}, '')
    }

    const informationTariff = () => {

        if (props.SelectedStore?.tariff) {

            let currentDate = Math.floor(new Date().getTime() / 1000);
            let procTariff = 0;
            if (currentDate < props.SelectedStore.tariff.expireDate) {
                let dateDifference = props.SelectedStore.tariff.expireDate - props.SelectedStore.tariff.startDate;
                let dateDifferenceTariff = props.SelectedStore.tariff.expireDate - currentDate;
                procTariff = Math.round(dateDifferenceTariff / dateDifference * 100)
            }

            return (
                <div className="d-flex align-items-center flex-column w-100 mb-3 mb-lg-6">
                    <div className="d-flex justify-content-between fw-bolder fs-6 text-gray-800 w-100 mt-auto mb-3">
                        <span>Выбран тариф "{props.SelectedStore.tariff.tariff.name}"</span>
                    </div>
                    <div className="w-100 bg-light-primary rounded mb-2" style={ {height: "24px"} }>
                        <div className={`bg-${procTariff < 50 ? "danger" : "success"} rounded`} role="progressbar" style={{height: "24px", width: `${procTariff}%`}}
                             aria-valuenow={procTariff} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div className="fw-semibold fs-7 text-primary w-100 mt-auto">
                        <span>Осталось: {expireDateTariff(new Date(props.SelectedStore.tariff.expireDate * 1000))}</span>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div className="flex-column-fluid px-4 px-lg-8 py-4" id="mp_app_sidebar_nav">
            <div id="mp_app_sidebar_nav_wrapper" className="d-flex flex-column hover-scroll-y pe-4 me-n4"
                 data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto"
                 data-kt-scroll-dependencies="#mp_app_sidebar_logo, #mp_app_sidebar_footer"
                 data-kt-scroll-wrappers="#mp_app_sidebar, #mp_app_sidebar_nav" data-kt-scroll-offset="5px">

                {Object.keys(props.SelectedStore).length > 1 &&

                    <>

                        {informationTariff()}

                        {/*<div className="d-flex mb-3 mb-lg-6">*/}
                        {/*    <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6">*/}
                        {/*        <span className="fs-6 text-gray-500 fw-bold">Бюджет</span>*/}
                        {/*        <div className="fs-2 fw-bold text-success">0,00</div>*/}
                        {/*    </div>*/}

                        {/*    <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4">*/}
                        {/*        <span className="fs-6 text-gray-500 fw-bold">Spent</span>*/}

                        {/*        <div className="fs-2 fw-bold text-danger">$8,029</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </>

                }

                <div className="mb-6">
                    <h3 className="text-gray-800 fw-bold mb-8">Маркетплейсы</h3>
                    <div className="row row-cols-2 row-cols-lg-3" data-kt-buttons="true"
                         data-kt-buttons-target="[data-kt-button]">

                        {props.MarketplaceList.map( (elMarket, indMarket) => {
                            return (
                                <div style={ elMarket.active ? {} : {opacity: "0.3", pointerEvents: 'none'} }
                                     key={indMarket} className="col mb-4">
                                    <a href="#"
                                       market={elMarket.name}
                                       data-kt-drawer-dismiss={'true'}
                                       onClick={(ev) => selectActiveMarketplace(ev, elMarket.name)}
                                       className="btn btn-icon btn-outline btn-bg-light btn-active-light-success btn-flex flex-column flex-center w-90px h-90px border-gray-200"
                                       data-kt-button="true">
                                        <div className="symbol symbol-50px" title={elMarket.title}>
                                            <img className="avatar-img"
                                                 src={`assets/media/icons/${elMarket.name}.png`} alt={elMarket.name}/>
                                        </div>

                                        {/*<span className="fs-7 fw-bold">{elMarket.title}</span>*/}

                                    </a>
                                </div>
                            )
                        } )}

                    </div>
                </div>

            </div>
        </div>
    );
};

export default SidebarNav;