import ContentKaspi from "../../conteiners/content_container/kaspi/ContentKaspi";
import ContentSber from "../../conteiners/content_container/sber/ContentSber";

const ContentMarketplace = (props) => {
    switch (props.Marketplace.name){
        case "kaspi":
            return <ContentKaspi />
        case 'sber':
            return <ContentSber />
        default:
            return null
    }

};

export default ContentMarketplace;