import React, {useEffect} from 'react';
import {getHeaderData, setJWT_Token, URL_DATA_BASE} from "../../settings";
import Swal from "sweetalert2";

const SignUp = (props) => {

    useEffect(()=> {
        window.KTPasswordMeter.createInstances();
        window.Inputmask({
            "mask" : "+7 (999) 999-99-99"
        }).mask("#kt_inputmask_phone");

        const form = document.getElementById('mp_sign_up_form');
        const validator = window.FormValidation.formValidation(
            form,
            {
                fields: {
                    "name": {
                        validators: {
                            notEmpty: {
                                message: 'Заполните поле'
                            }
                        }},
                    "password": {
                        validators: {
                            notEmpty: {
                                message: 'Заполните поле'
                            }
                        }},
                    "email": {
                        validators: {
                            notEmpty: {
                                message: 'Заполните поле'
                            }
                        }},
                    "phone": {
                        validators: {
                            notEmpty: {
                                message: 'Заполните поле'
                            }
                        }},
                    },
                plugins: {
                    trigger: new window.FormValidation.plugins.Trigger(),
                    bootstrap: new window.FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',
                        eleValidClass: ''
                    })
                }
            }
        );

        const eventListener = (e) => {
            e.preventDefault();
            if (validator) {
                validator.validate().then(function (status) {

                    if (status === 'Valid') {
                        const domElInput = document.querySelectorAll('input');
                        const objectSend = {};
                        domElInput.forEach(el => objectSend[el.getAttribute('name')] = el.value)

                        objectSend.phone = objectSend.phone.replace(/\D/g, '');

                        if (objectSend.password !== objectSend.repeat_password) {
                            showMiniModal('Пароль не совпадает. Повторите ввод пароля', true);
                            return
                        }

                        if (objectSend.phone.length < 11) {
                            showMiniModal('Не корректно указан номер телефона.', true);
                            return
                        }

                        const removeIndicatorButton = () => {
                            submitButton.removeAttribute('data-kt-indicator');
                            submitButton.disabled = false;
                        }
                        // objectSend.marketplace = props.MarketplaceList;
                        const url = `${URL_DATA_BASE}/user/create`;
                        submitButton.setAttribute('data-kt-indicator', 'on');
                        submitButton.disabled = true;

                        fetch(url, getHeaderData('POST', objectSend))
                            .then(response=>response.json())
                            .then(body=> {
                                removeIndicatorButton();
                                if (body?.detail) {
                                    showMiniModal(body.detail, true);
                                } else {
                                    showMiniModal('Поздравляем! Вы успешно зарегистрировались!');
                                    props.changeToken(body.JWT);
                                }
                            })
                            .catch(error=> {
                                removeIndicatorButton();
                                console.log(error);
                            })
                    }
                });
            }
        }

        const showMiniModal = (text, isError = false) => {
            Swal.fire({
                text,
                icon: isError ? "error" : "success",
                buttonsStyling: false,
                confirmButtonText: isError ? "Закрыть" : "Ок",
                customClass: {
                    confirmButton: "btn btn-warning"
                },
                timer: 5000
            });
        }

        const submitButton = document.getElementById('create_button');
        submitButton.addEventListener('click', eventListener);

        return () => {
            submitButton.removeEventListener('click', eventListener);
        }

        }, [])

    const style = {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto'
    }

    const repeatPassword = () => {
        const domEl = document.querySelector('#repeat_password');
        const domElPassword = document.querySelector('#password');
        const currentRepeatPassword = domEl.value;
        if (!currentRepeatPassword) {
            domEl.classList.remove('is-invalid');
            domEl.classList.remove('is-valid');
            return
        }
        if (currentRepeatPassword === domElPassword.value) {
            domEl.classList.add('is-valid');
            domEl.classList.remove('is-invalid');
        } else {
            domEl.classList.remove('is-valid');
            domEl.classList.add('is-invalid');
        }

    }

    return (
        <div className="d-flex flex-column flex-root">

            <div style={style} className="d-flex flex-center w-lg-50 p-10">
                <div className="card rounded-3 w-md-550px">

                    <div className="card-header">
                        <h3 className="card-title"></h3>
                        <div className="card-toolbar">
                            <button onClick={ ()=> props.changeTypePage('') } type="button" className="btn btn-sm btn-light">
                                На главную
                            </button>
                        </div>
                    </div>

                    <div className="card-body p-10 p-lg-20">

                        <form className="form w-100" id="mp_sign_up_form">

                            <div className="text-center mb-11">
                                <h1 className="text-dark fw-bolder mb-3">Регистрация</h1>
                            </div>

                            <div className="fv-row mb-8">
                                <input type="text"
                                       placeholder="Имя" name="name" autoComplete="off"
                                       className="form-control bg-transparent"/>
                            </div>

                            <div className="fv-row mb-8">
                                <input type="text"
                                       id={'kt_inputmask_phone'}
                                       placeholder="Телефон" name="phone" autoComplete="off"
                                       className="form-control bg-transparent"/>
                            </div>

                            <div className="fv-row mb-8">
                                <input type="text"
                                       placeholder="Email" name="email" autoComplete="off"
                                       className="form-control bg-transparent"/>
                            </div>

                            <div className="fv-row mb-8" data-kt-password-meter="true">
                                <div className="mb-1">
                                    <div className="position-relative mb-3">
                                        <input className="form-control bg-transparent"
                                               id={'password'}
                                               onChange={repeatPassword}
                                               type="password"
                                               placeholder="Password" name="password" autoComplete="off"/>
                                        <span
                                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                            data-kt-password-meter-control="visibility">
                                        <i className="bi bi-eye-slash fs-2"></i>
                                        <i className="bi bi-eye fs-2 d-none"></i>
                                    </span>
                                    </div>

                                    <div className="d-flex align-items-center mb-3"
                                         data-kt-password-meter-control="highlight">
                                        <div
                                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                        <div
                                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                        <div
                                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                                    </div>

                                </div>

                                <div className="text-muted">
                                    Используйте 8 или более символов, сочетая буквы, цифры и символы.
                                </div>
                            </div>

                            <div className="fv-row mb-8">

                                <input id={'repeat_password'}
                                       onChange={repeatPassword}
                                       placeholder="Repeat Password" name="repeat_password" type="password"
                                       autoComplete="off" className="form-control bg-transparent"/>

                            </div>


                            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div></div>
                            </div>

                            <div className="d-grid mb-10">
                                <button id={'create_button'} type="submit" className="btn btn-primary">
                                    <span className="indicator-label">Создать аккаунт и войти</span>
                                    <span className="indicator-progress">Пожалуйста ожидайте...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                            <div className="text-gray-500 text-center fw-semibold fs-6">Есть аккаунт?
                                <a href="#" onClick={()=> props.changeTypePage('signIn')}
                                   className="link-primary"> Авторизация</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;