import React from 'react';

const StoreSettingsSber = (props) => {

    const store = props.Marketplace.singleStore;

    const changeActive = () => {
        props.changeActive(!store.active);
    }

    const updateStoreData = () => {
        console.log(props)
    }

    return (
        <div className="card-body">

            <form className="form">

                <div className="fv-row row mb-5">
                    <div className="col-md-3 d-flex align-items-center">
                        <label className="fs-6 fw-semibold">Активный</label>
                    </div>

                    <div className="col-md-9">
                        <div className="form-check form-switch form-check-custom form-check-solid me-10">
                            <input checked={store.active} onChange={changeActive} value={'1'} className="form-check-input h-30px w-50px" name="active"
                                   type="checkbox"/>
                            <label className="form-check-label">
                                Активный / Архивный, кабинет
                            </label>
                        </div>
                    </div>
                </div>

                <div className="fv-row row mb-5">
                    <div className="col-md-3 d-flex align-items-center">
                        <label className="fs-6 fw-semibold">Наименование</label>
                    </div>

                    <div className="col-md-9">
                        <input onChange={(ev)=> props.changeName(ev.target.value)} value={String(store.name)} type="text" className="form-control"
                               aria-describedby="inputGroup-sizing-lg" name={'name'}/>
                        <label className="form-check-label">
                            Наименование кабинета
                        </label>
                    </div>
                </div>

                <div className="fv-row row mb-5">
                    <div className="col-md-3 d-flex align-items-center">
                        <label className="fs-6 fw-semibold">Кол-во доступны к продажек</label>
                    </div>

                    <div className="col-md-9">
                        <input value={String(store.activeCount)} disabled={true} type="number"
                               className="form-control" aria-describedby="inputGroup-sizing-lg"/>
                        <label className="form-check-label">
                            Количество карточек доступных к продаже
                        </label>
                    </div>
                </div>

                <div className="fv-row row mb-5">
                    <div className="col-md-3 d-flex align-items-center">
                        <label className="fs-6 fw-semibold">Token авторизации</label>
                    </div>

                    <div className="col-md-9">
                        <input onChange={()=>{}} value={String(store.token)} disabled={true} type="text"
                               className="form-control"
                               aria-describedby="inputGroup-sizing-lg" name={'token'}/>
                        <label className="form-check-label">
                            Token авторизации и доступа к API функционалу кабинета.
                        </label>
                    </div>
                </div>

                <div className="fv-row row mb-5">
                    <div className="col-md-3 d-flex align-items-center">
                        <label className="fs-6 fw-semibold">Пользователь</label>
                    </div>

                    <div className="col-md-9">
                        <input onChange={(ev)=> props.changeLogin(ev.target.value)} value={String(store.login)} type="text" className="form-control"
                               aria-describedby="inputGroup-sizing-lg" name={'login'}/>
                        <label className="form-check-label">
                            Пользователь доступа к личному кабинету магазина
                        </label>
                    </div>
                </div>

                <div className="fv-row row mb-5">
                    <div className="col-md-3 d-flex align-items-center">
                        <label className="fs-6 fw-semibold">Пароль</label>
                    </div>

                    <div className="col-md-9">
                        <input onChange={(ev)=> props.changePassword(ev.target.value)} value={String(store.password)} type="password" className="form-control"
                               aria-describedby="inputGroup-sizing-lg" name={'password'}/>
                        <label className="form-check-label">Пароль доступа к личному кабинету
                            магазина</label>
                    </div>
                </div>

                {store.updateStoreData &&
                    <div className="row mt-12">
                        <div className="col-md-9 offset-md-3">

                            <button onClick={ev => props.changeSingleStore(
                                props.Merchant.storesSber.filter(el => el.id === store.id)[0])}
                                    type="button" className="btn btn-light me-3">
                                Отменить
                            </button>

                            <button onClick={updateStoreData} type="button" className="btn btn-primary"
                                    id="mp_save_change_store">
                                <span className="indicator-label">Сохранить</span>
                                <span className="indicator-progress">Пожалуйста ожидайте...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>

                        </div>
                    </div>
                }

            </form>

        </div>
    );
};

export default StoreSettingsSber;