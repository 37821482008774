import React, {useEffect} from 'react';
import {KTSVG} from "../KTSVG";
import Swal from 'sweetalert2'
import {getHeaderData, URL_DATA_BASE} from "../settings";

const ModalCreateStore = (props) => {

    useEffect(() => {
        const form = document.getElementById('mp_modal_new_store_form');

        const objValidateFields = {};

        for (let field of Object.keys(props.data)) {
            if (props.data[field]?.required)  {
                objValidateFields[field] = {
                    validators: {
                        notEmpty: {
                            message: 'Заполните поле'
                        }
                    }
                }
            }
        }

        const validator = window.FormValidation.formValidation(
            form,
            {
                fields: objValidateFields,
                plugins: {
                    trigger: new window.FormValidation.plugins.Trigger(),
                    bootstrap: new window.FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',
                        eleValidClass: ''
                    })
                }
            }
        );

        const eventListener = (e) => {
            e.preventDefault();

            if (validator) {
                validator.validate().then(function (status) {

                    if (status === 'Valid') {

                        const url = `${URL_DATA_BASE}/${props.Marketplace.name}/store/create`;
                        const objectSend = {}
                        for (let field of Object.keys(props.data)) {
                            const domEl = document.querySelector(`.create-input[name="${field}"]`);
                            objectSend[field] = domEl.value || domEl.getAttribute('value-data');
                        }

                        if (!Object.keys(objectSend).length) return null;

                        submitButton.setAttribute('data-kt-indicator', 'on');
                        submitButton.disabled = true;

                        fetch(url, getHeaderData('POST', objectSend, props.UserJWT))
                            .then(resp => resp.json())
                            .then(body => {
                                if (body?.detail) {
                                    Swal.fire({
                                        text: (typeof body?.detail !== "string" ? 'Ошибка отправки запроса создания нового магазина' : body?.detail),
                                        icon: "error",
                                        buttonsStyling: false,
                                        confirmButtonText: "Закрыть",
                                        customClass: {
                                            confirmButton: "btn btn-warning"
                                        },
                                        timer: 5000
                                    });
                                } else {
                                    Swal.fire({
                                        text: 'Магазин успешно добавлен',
                                        icon: "success",
                                        buttonsStyling: false,
                                        confirmButtonText: "Ок",
                                        customClass: {
                                            confirmButton: "btn btn-success"
                                        },
                                        timer: 5000
                                    });
                                    closeModal();
                                    props.updateMerchantData();
                                }
                                removeIndicatorButton();
                                }).catch(error => {console.log(error); removeIndicatorButton();})

                        const removeIndicatorButton = () => {
                            submitButton.removeAttribute('data-kt-indicator');
                            submitButton.disabled = false;
                        }
                    }
                });
            }
        }

        const submitButton = document.getElementById('mp_modal_new_store_submit');
        submitButton.addEventListener('click', eventListener);

        return () => {
            submitButton.removeEventListener('click', eventListener);
        }

    }, [])

    const closeModal= () => {
        window.$('#mp_modal_create_store').modal('hide');
    }

    const jsxField = [];

    for (let field of Object.keys(props.data)) {
        jsxField.push(
            selectInputDom(field, props.data[field])
        )
    }

    return (
        <div className="modal fade" id="mp_modal_create_store" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content rounded">

                    <div className="modal-header pb-0 border-0 justify-content-end">
                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                            <KTSVG path={'assets/media/icons/duotune/arrows/arr061.svg'}
                                   className={'svg-icon-1'} />
                        </div>
                    </div>

                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                        <form id="mp_modal_new_store_form" className="form" action="#">

                            <div className="mb-13 text-center">
                                <h1 className="mb-3">Добавить новый магазин</h1>
                            </div>

                            {jsxField}

                            <div className="text-center">

                                <button onClick={closeModal} type="reset" className="btn btn-light me-3">
                                    Отменить
                                </button>

                                <button type="submit" id="mp_modal_new_store_submit" className="btn btn-primary">
                                    <span className="indicator-label">Создать</span>
                                    <span className="indicator-progress">Пожалуйста ожидайте...
							            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>

                            </div>

                        </form>
                    </div>


                </div>
            </div>
        </div>
    );
};

const selectInputDom = (name, data) => {

    return (
        <div key={name} className="d-flex flex-column mb-8 fv-row">
            {data?.title &&
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span className="required">{data.title}</span>
                </label>
            }
            <input onChange={()=>{}} type={data.type}
                   value-data={data?.value || ''}
                   className="form-control form-control-solid create-input"
                   placeholder={data?.placeholder} name={name} hidden={data?.hidden || false} />
        </div>
    )

}

export default ModalCreateStore;