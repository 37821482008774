import { connect } from "react-redux";
import App from "../components/App";
import {FUNCTION_MARKETPLACE_LIST} from "../reducers/Marketplace";
import {FUNCTION_USER_JWT} from "../reducers/UserJWT";


function mapStateToProps(state) {
    const { UserJWT } = state;
    return { UserJWT }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE_LIST();
    const funcDispatchUserJWT = new FUNCTION_USER_JWT();
    return {
        'changeMarketplaceList': (marketplaceList) => dispatch(funcDispatch.changeMarketplaceList(marketplaceList)),
        'changeToken': (token) => dispatch(funcDispatchUserJWT.changeToken(token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);