import { connect } from "react-redux";
import Landing from "../../components/landing/Landing";
import {FUNCTION_AUTH_TYPE_PAGE} from "../../reducers/AuthenticationTypePage";

function mapStateToProps(state) {
    const { AuthenticationTypePage } = state;
    return { AuthenticationTypePage }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_AUTH_TYPE_PAGE();
    return {
        'changeTypePage': (value) => dispatch(funcDispatch.changeTypePage(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);