import React, {useEffect} from 'react';
import {KTSVG} from "../KTSVG";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sign from "../../conteiners/authentication/Sign";

const Landing = (props) => {

    useEffect(() => {
        if (!props.AuthenticationTypePage) {
            window.KTDrawer.createInstances();
            window.KTSticky.createInstances();
        }
    }, [props.AuthenticationTypePage])

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    if (props.AuthenticationTypePage) return <Sign />

    return (
        <div className="d-flex flex-column flex-root">

            <div className="mb-0" id="home">
                <div
                    className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                    style={{
                        backgroundImage: "url(assets/media/svg/illustrations/landing.svg)"
                    }}>
                    <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header"
                         data-kt-sticky-zindex="95"
                         data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                        <div className="container">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center flex-equal">
                                    <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                            id="kt_landing_menu_toggle">
                                        <span className="svg-icon svg-icon-2hx">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
												<path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="currentColor"/>
												<path opacity="0.3"
                                                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                      fill="currentColor"/>
											</svg>
										</span>

                                    </button>
                                    <a href="#">
                                        <img alt="Logo" src="assets/media/logos/TSG-dark.svg"
                                             className="logo-default h-50px h-lg-50px svg-icon-success"/>
                                        <img alt="Logo" src="assets/media/logos/TSG.svg"
                                             className="logo-sticky h-30px h-lg-50px"/>
                                    </a>
                                </div>
                                <div className="d-lg-block" id="kt_header_nav_wrapper">
                                    <div id={'mp_drawer_landing'} className="d-lg-block p-5 p-lg-0"
                                         data-kt-drawer="true"
                                         data-kt-drawer-name="landing-menu"
                                         data-kt-drawer-activate="{default: true, lg: false}"
                                         data-kt-drawer-overlay="false"
                                         data-kt-drawer-width="200px"
                                         data-kt-drawer-direction="start"
                                         data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                         data-kt-swapper="true"
                                         data-kt-swapper-mode="prepend"
                                         data-kt-swapper-parent="{default: '#home', lg: '#kt_header_nav_wrapper'}"
                                    >
                                        <div
                                            className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                                            id="mp_landing_menu">
                                            <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                   href="#mp_body" data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss={'true'}
                                                   >Домашняя</a>
                                            </div>
                                            <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                   href="#how-it-works" data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss={'true'}
                                                   >Как это работает</a>
                                            </div>
                                            <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                   href="#tariffs" data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss={'true'}
                                                   >Тарифы</a>
                                            </div>
                                            <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                   href="#what-do-we-offer" data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss={'true'}
                                                   >Что мы предлагаем</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="flex-equal text-end ms-1">
                                    <a onClick={()=> props.changeTypePage('signIn')}
                                        href="#"
                                       className="btn btn-success">Войти</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                        <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                            <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-5">
                                {/*<span className={"bg-gradient bg-info"}>*/}
                                {'Автоматизируйте работу с маркетплейсами'.toLocaleUpperCase()}
							    {/*</span>*/}
                            </h1>
                            <h3 className="text-white lh-base mb-15">
                                <span className={'text text-success'}>
                                    Увеличте свой объем продаж
                                </span> автоматизировав рутинные задачи по работе с маркетплейсами.
                            </h3>
                            <a onClick={()=> props.changeTypePage('signUp')}
                               href="#" className="btn btn-success fs-2">Зарегистрироваться</a>
                        </div>
                        <div className="d-flex flex-center flex-wrap position-relative px-5">
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Kaspi KZ">
                                <img src="assets/media/icons/kaspi.png" className="mh-30px mh-lg-40px"
                                     alt=""/>
                            </div>
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Wildberries">
                                <img src="assets/media/icons/wb.png" className="mh-30px mh-lg-40px"
                                     alt=""/>
                            </div>
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                                 title="СберМегаМаркет">
                                <img src="assets/media/icons/sber.png" className="mh-30px mh-lg-40px" alt=""/>
                            </div>
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                                 title="Lamoda">
                                <img src="assets/media/icons/lamoda.png" className="mh-30px mh-lg-40px" alt=""/>
                            </div>
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Ozon">
                                <img src="assets/media/icons/ozon.png" className="mh-30px mh-lg-40px" alt=""/>
                            </div>
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Yandex market">
                                <img src="assets/media/icons/yandex.png" className="mh-30px mh-lg-40px"
                                     alt=""/>
                            </div>
                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Jusan">
                                <img src="assets/media/icons/jusan.png" className="mh-30px mh-lg-40px"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="mb-n10 mb-lg-n20 z-index-2">
                <div className="container">

                    <div className="text-center mb-17">
                        <h3 className="fs-2hx text-dark mb-5" id="how-it-works"
                            data-kt-scroll-offset="{default: 100, lg: 150}">Как это работает</h3>
                        <div className="fs-5 text-muted fw-bold">
                            Зарегистрируйтесь в нашей системе управления маркетплейсами и вы сможете управлять всеми кабинетами в одном месте.
                        </div>
                    </div>

                    <div className="row w-100 gy-10 mb-md-20">
                        <div className="col-md-4 px-5">
                            <div className="text-center mb-10 mb-md-0">
                                <img src="assets/media/illustrations/marketplace.png" className="mh-125px mb-9" alt=""/>
                                <div className="d-flex flex-center mb-5">
                                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Управление в одном месте</div>
                                </div>
                                <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                    Подключайте все свои магазины расположенные на различных маркетплейсах и управляйте ими в единном кабинете продавца.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 px-5">
                            <div className="text-center mb-10 mb-md-0">
                                <img src="assets/media/illustrations/analytics.png" className="mh-125px mb-9" alt=""/>
                                <div className="d-flex flex-center mb-5">
                                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Сводная аналитика</div>
                                </div>
                                <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                    Анализируйте свои заказы и продажи в разрезе марктетплейсов и получайте наиболее выгодные каналы продаж.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 px-5">
                            <div className="text-center mb-10 mb-md-0">
                                <img src="assets/media/illustrations/sigma-1/9.png" className="mh-125px mb-9"
                                     alt=""/>
                                <div className="d-flex flex-center mb-5">
                                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Интеграция с 1С</div>
                                </div>
                                <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                    Скачивайте наш модуль интеграции для вашей учетной системы на базе 1С:Предприятие и автоматизируйте обмен товарами, заказами, остатками, ценами с маркетплейсами.
                                </div>
                            </div>
                        </div>
                    </div>

                    <Slider {...settings}>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                            <img src="assets/media/product-demos/demo1.png" className="card-rounded shadow mw-100"
                                 alt=""/>
                        </div>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                            <img src="assets/media/product-demos/demo2.png" className="card-rounded shadow mw-100"
                                 alt=""/>
                        </div>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                            <img src="assets/media/product-demos/demo4.png" className="card-rounded shadow mw-100"
                                 alt=""/>
                        </div>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                            <img src="assets/media/product-demos/demo5.png" className="card-rounded shadow mw-100"
                                 alt=""/>
                        </div>
                    </Slider>

                </div>
            </div>

            <div className="mt-sm-n20">
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                <div className="py-20 landing-dark-bg">
                    <div className="container">
                        <div className="d-flex flex-column container pt-lg-20">
                            <div className="mb-13 text-center">
                                <h1 className="fs-2hx fw-bold text-white mb-5" id="tariffs"
                                    data-kt-scroll-offset="{default: 100, lg: 150}">Тарифы</h1>
                                <div className="text-gray-600 fw-semibold fs-5">
                                    Вы можете бесплатно опробовать полный функционал нашей системы в течении 7 дней с момента регистрации.
                                </div>
                            </div>
                            <div className="text-center" id="kt_pricing">
                                <div className="nav-group landing-dark-bg d-inline-flex mb-15" data-kt-buttons="true"
                                     style={{border: '1px dashed #2B4666'}}>
                                    <a href="#"
                                       className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active"
                                       data-kt-plan="month">Monthly</a>
                                    <a href="#"
                                       className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3"
                                       data-kt-plan="annual">Annual</a>
                                </div>
                                <div className="row g-10">
                                    <div className="col-xl-4">
                                        <div className="d-flex h-100 align-items-center">
                                            <div
                                                className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-dark mb-5 fw-boldest">Startup</h1>
                                                    <div className="text-gray-400 fw-semibold mb-5">Best Settings for
                                                        Startups
                                                    </div>
                                                    <div className="text-center">
                                                        <span className="mb-2 text-primary">$</span>
                                                        <span className="fs-3x fw-bold text-primary"
                                                              data-kt-plan-price-month="99"
                                                              data-kt-plan-price-annual="999">99</span>
                                                        <span className="fs-7 fw-semibold opacity-50"
                                                              data-kt-plan-price-month="Mon"
                                                              data-kt-plan-price-annual="Ann">/ Mon</span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 10 Active Users</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 30 Project Integrations</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800">Keen Analytics Platform</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800">Targets Timelines & Files</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800">Targets Timelines & Files</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800">Targets Timelines & Files</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800">Targets Timelines & Files</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1'} />
                                                    </div>
                                                    <div className="d-flex flex-stack">
                                                        <span className="fw-semibold fs-6 text-gray-800">Unlimited Projects</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1'} />
                                                    </div>
                                                </div>
                                                <a href="#" className="btn btn-primary">Select</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="d-flex h-100 align-items-center">
                                            <div
                                                className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-white mb-5 fw-boldest">Business</h1>
                                                    <div className="text-white opacity-75 fw-semibold mb-5">Best
                                                        Settings for Business
                                                    </div>
                                                    <div className="text-center">
                                                        <span className="mb-2 text-white">$</span>
                                                        <span className="fs-3x fw-bold text-white"
                                                              data-kt-plan-price-month="199"
                                                              data-kt-plan-price-annual="1999">199</span>
                                                        <span className="fs-7 fw-semibold text-white opacity-75"
                                                              data-kt-plan-price-month="Mon"
                                                              data-kt-plan-price-annual="Ann">/ Mon</span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 10 Active Users</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-white'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 30 Project Integrations</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-white'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Keen Analytics Platform</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-white'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Targets Timelines & Files</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-white'} />
                                                    </div>
                                                    <div className="d-flex flex-stack">
                                                        <span className="fw-semibold fs-6 text-white opacity-75">Unlimited Projects</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen040.svg'}
                                                               className={'svg-icon-1 svg-icon-white'} />
                                                    </div>
                                                </div>
                                                <a href="#"
                                                   className="btn btn-color-primary btn-active-light-primary btn-light">Select</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="d-flex h-100 align-items-center">
                                            <div
                                                className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-dark mb-5 fw-boldest">Enterprise</h1>
                                                    <div className="text-gray-400 fw-semibold mb-5">Best Settings for
                                                        Enterprise
                                                    </div>
                                                    <div className="text-center">
                                                        <span className="mb-2 text-primary">$</span>
                                                        <span className="fs-3x fw-bold text-primary"
                                                              data-kt-plan-price-month="999"
                                                              data-kt-plan-price-annual="9999">999</span>
                                                        <span className="fs-7 fw-semibold opacity-50"
                                                              data-kt-plan-price-month="Mon"
                                                              data-kt-plan-price-annual="Ann">/ Mon</span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 10 Active Users</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 30 Project Integrations</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Keen Analytics Platform</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                    <div className="d-flex flex-stack mb-5">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Targets Timelines & Files</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                    <div className="d-flex flex-stack">
                                                        <span
                                                            className="fw-semibold fs-6 text-gray-800 text-start pe-3">Unlimited Projects</span>
                                                        <KTSVG path={'assets/media/icons/duotune/general/gen043.svg'}
                                                               className={'svg-icon-1 svg-icon-success'} />
                                                    </div>
                                                </div>
                                                <a href="#" className="btn btn-primary">Select</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="mb-9">
                <div className="container">

                    <div className="text-center mb-17">
                        <h3 className="fs-2hx text-dark mb-5" id="what-do-we-offer"
                            data-kt-scroll-offset="{default: 100, lg: 150}">Мы поможем вам работать более эффективно</h3>
                        <div className="fs-5 text-muted fw-bold">
                            Мы предлагаем следующий набор инстументов для автоматизации работы на маркетплейсах
                        </div>
                    </div>

                    <div className="row g-0">
                        <div className="col-md-6 mb-10">
                            <div className="bg-white bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                <div
                                    className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
                                    <KTSVG path={'assets/media/icons/duotune/coding/cod001.svg'}
                                           className={'svg-icon-info svg-icon-3x'} />
                                </div>
                                <h1 className="mb-5">Анализ цен</h1>
                                <div className="fs-4 text-gray-600 py-3">
                                    Мы собираем информацию о позиции ваших товаров среди конкурентов. Система автоматического демпинга цен позволит повысить продажи вашего товара.
                                </div>
                                {/*<a href="/html/metronic/docs/getting-started/build/gulp"*/}
                                {/*   className="btn btn-lg btn-flex btn-link btn-color-info">Install now*/}
                                {/*    <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}*/}
                                {/*           className={'ms-2 svg-icon-3'} />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 mb-10">
                            <div className="bg-white bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                <div
                                    className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                                    <KTSVG path={'assets/media/icons/duotune/art/art006.svg'}
                                           className={'svg-icon-success svg-icon-3x'} />
                                </div>
                                <h1 className="mb-5">Контроль остатков</h1>
                                <div className="fs-4 text-gray-600 py-3">
                                    Мы разработали систему единного контроля остатков на всех маркетплейсах. Загружайти остатки со своих складов и вы больше не получите заказы на нулевой остаток.
                                </div>
                                {/*<a href="https://preview.keenthemes.com/metronic8/demo1/layout-builder.html"*/}
                                {/*   className="btn btn-lg btn-flex btn-link btn-color-success">Preview and export*/}
                                {/*    <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}*/}
                                {/*           className={'ms-2 svg-icon-3'} />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 mb-10">
                            <div className="bg-white bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                <div
                                    className="d-flex flex-center w-60px h-60px rounded-3 bg-light-danger bg-opacity-90 mb-10">
                                    <KTSVG path={'assets/media/icons/duotune/abstract/abs027.svg'}
                                           className={'svg-icon-danger svg-icon-3x'} />
                                </div>
                                <h1 className="mb-5">Обработка заказов</h1>
                                <div className="fs-4 text-gray-600 py-3">
                                    Мы можем автоматически принимать ваши заказы, если есть свободный остаток по товарам из нового заказа. Данный функционал позволит вам сократить время на обработке заказов.
                                </div>
                                {/*<a href="/html/metronic/docs/base/utilities"*/}
                                {/*   className="btn btn-lg btn-flex btn-link btn-color-danger">Learn more*/}
                                {/*    <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}*/}
                                {/*           className={'ms-2 svg-icon-3'} />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 mb-10">
                            <div className="bg-white bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                <div
                                    className="d-flex flex-center w-60px h-60px rounded-3 bg-light-warning bg-opacity-90 mb-10">
                                    <KTSVG path={'assets/media/icons/duotune/abstract/abs026.svg'}
                                           className={'svg-icon-warning svg-icon-3x'} />
                                </div>
                                <h1 className="mb-5">Отчеты</h1>
                                <div className="fs-4 text-gray-600 py-3">
                                    Мы собираем информацию о Ваших продажах и выводим их в удобном формате. Вы можете анализровать количество продаж и заказов. Есть сравнение с предыдущим периодом.
                                </div>
                                {/*<a href="/html/metronic/docs/general/stepper"*/}
                                {/*   className="btn btn-lg btn-flex btn-link btn-color-warning">Browse components*/}
                                {/*    <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}*/}
                                {/*           className={'ms-2 svg-icon-3'} />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 mb-10">
                            <div className="bg-white bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                <div
                                    className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                                    <KTSVG path={'assets/media/icons/duotune/coding/cod003.svg'}
                                           className={'svg-icon-success svg-icon-3x'} />
                                </div>
                                <h1 className="mb-5">Загрузка данных</h1>
                                <div className="fs-4 text-gray-600 py-3">
                                    Вы можете загружать большие объемы данных по всем вашим товарам, остаткам и ценам с помощью простых и удобных шаблонов. Просматривать загруженную информацию в удобном форме с помощью гибкой системы фильтров и сортировки.
                                </div>
                                {/*<a href="/html/metronic/docs/forms/flatpickr"*/}
                                {/*   className="btn btn-lg btn-flex btn-link btn-color-success">Learn more*/}
                                {/*    <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}*/}
                                {/*           className={'ms-2 svg-icon-3'} />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 mb-10">
                            <div className="bg-white bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                <div
                                    className="d-flex flex-center w-60px h-60px rounded-3 bg-light-primary bg-opacity-90 mb-10">
                                    <KTSVG path={'assets/media/icons/duotune/abstract/abs038.svg'}
                                           className={'svg-icon-primary svg-icon-3x'} />
                                </div>
                                <h1 className="mb-5">Интеграция</h1>
                                <div className="fs-4 text-gray-600 py-3">
                                    Интегрируйте вашу учетную систему на базе "1С:Предприятие" с помощью нашего модуля и автоматизируйте обмен данным с маркетплейсами по остаткам, ценам и заказам.
                                </div>
                                {/*<a href="#"*/}
                                {/*   className="btn btn-lg btn-flex btn-link btn-color-primary">*/}
                                {/*    Browse icons*/}
                                {/*    <KTSVG path={'assets/media/icons/duotune/arrows/arr064.svg'}*/}
                                {/*           className={'ms-2 svg-icon-3'} />*/}
                                {/*</a>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mt-20 mb-n20 position-relative z-index-2">

                <div className="container">

                    <div className="text-center mb-17">

                        <h3 className="fs-2hx text-dark mb-5" id="clients"
                            data-kt-scroll-offset="{default: 125, lg: 150}">Попробуйте использовать наш инструмент, это бесплатно</h3>

                        <div className="fs-5 text-muted fw-bold">
                            Срок пробного периода — 7 дней
                        </div>

                    </div>

                    <div
                        className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
                        style={{background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)"}}
                    >

                        <div className="my-2 me-5">

                            <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">Начните с нами сегодня,
                                <span className="fw-normal">Увеличивайте свой доход!!</span></div>

                            <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">
                                Начните повышать свой доход уже сейчас
                            </div>

                        </div>

                        <a onClick={()=> props.changeTypePage('signUp')} href="#"
                           className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">
                            Зарегистрироваться
                        </a>

                    </div>

                </div>

            </div>


            <div className="mb-0">
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                <div className="landing-dark-bg pt-20">
                    <div className="landing-dark-separator"></div>
                    <div className="container">
                        <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                            <div className="d-flex align-items-center order-2 order-md-1">
                                <a href="#">
                                    <img alt="Logo" src="assets/media/logos/TSG-dark.svg" title={'TOP SALE GURU'} className="h-15px h-md-30px"/>
                                </a>
                                <span className="mx-5 fs-6 fw-semibold text-gray-600 pt-1">
                                    &copy; 2022 Top Sale.
                                </span>
                            </div>
                            <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                <li className="menu-item">
                                    <a href="#"
                                       className="menu-link px-2">О нас</a>
                                </li>
                                <li className="menu-item mx-5">
                                    <a href="#"
                                       className="menu-link px-2">Тех. поддержка</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#" className="menu-link px-2">Покупка</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Landing;