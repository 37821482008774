import React, {useEffect} from 'react';
import MenuWrapper from "./menu_wrapper/MenuWrapper";
import LogoWrapper from "./logo_wrapper/LogoWrapper";
import Navbar from "./navbar/Navbar";

const Header = () => {

    useEffect(()=> {
        window.KTSticky.createInstances();
    }, [])

    return (
        <div id="mp_app_header" className="app-header" data-kt-sticky="true"
             data-kt-sticky-activate-="true" data-kt-sticky-name="app-header-sticky"
             data-kt-sticky-offset="{default: '200px', lg: '300px'}">
            <div className="app-container container-xxl d-flex align-items-stretch justify-content-between"
                 id="mp_app_header_container">
                <div className="app-header-wrapper d-flex flex-grow-1 align-items-stretch justify-content-between"
                     id="mp_app_header_wrapper">
                    <MenuWrapper />
                    <LogoWrapper />
                    <Navbar />
                </div>
            </div>
        </div>
    );
};

export default Header;