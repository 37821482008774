import React, {useEffect} from 'react';
import {KTSVG} from "../../KTSVG";
import {dataUserJwtDecoded} from "../../settings";

const SidebarLogo = (props) => {

    useEffect(()=> {
        window.KTMenu.createInstances();
        const themeColor = window.KTThemeMode.getMode();
        const elNode = document.querySelector(`[data-kt-value="${themeColor}"]`)
        elNode.classList.add('active')
    }, [])

    const changeColorTheme = (themeColor) => {
        const CurrentThemeColor = window.KTThemeMode.getMode();
        const elNodeCurrent = document.querySelector(`[data-kt-value="${CurrentThemeColor}"]`);
        elNodeCurrent.classList.remove('active')
        const elNode = document.querySelector(`[data-kt-value="${themeColor}"]`);
        elNode.classList.add('active');
        window.KTThemeMode.setMode(themeColor);
    }

    // const totalCountStores = (props.Merchant.storesSber.length + props.Merchant.storesKaspi.length)
    const dataUser = dataUserJwtDecoded(props.UserJWT)

    return (
        <div className="d-flex flex-stack px-4 px-lg-6 py-3 py-lg-8" id="mp_app_sidebar_logo">
            <a href="#">
                <img alt="Logo" src="assets/media/logos/TSG.svg" className="h-30px h-lg-50px theme-light-show"/>
                <img alt="Logo" src="assets/media/logos/TSG-dark.svg" className="h-30px h-lg-50px theme-dark-show"/>
            </a>

            <div className="ms-3">

                <div className="cursor-pointer position-relative symbol symbol-circle symbol-40px"
                     data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
                     data-kt-menu-placement="bottom-end">
                    <img src="assets/media/avatars/blank.png" alt="user"/>
                    <div
                        className="position-absolute rounded-circle bg-success start-100 top-100 h-8px w-8px ms-n3 mt-n3"></div>
                </div>

                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                    data-kt-menu="true">

                    <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                            <div className="symbol symbol-50px me-5">
                                <img alt="Logo" src="assets/media/avatars/blank.png"/>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="fw-bold d-flex align-items-center fs-5">{dataUser.name}
                                    {/*<span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span>*/}
                                </div>
                                <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{dataUser.email}</a>
                            </div>
                        </div>
                    </div>

                    <div className="separator my-2"></div>

                    {/*<div className="menu-item px-5">*/}
                    {/*    <a href="#" className="menu-link px-5">Мой профиль</a>*/}
                    {/*</div>*/}

                    {/*<div className="menu-item px-5">*/}
                    {/*    <a href="#" className="menu-link px-5">*/}
                    {/*        <span className="menu-text">Мои магазины</span>*/}
                    {/*        <span className="menu-badge">*/}
                    {/*            <span className="badge badge-light-danger badge-circle fw-bold fs-7">{totalCountStores}</span>*/}
                    {/*        </span>*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                    <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                         data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                        <a href="#" className="menu-link px-5">
                            <span className="menu-title">Мои подписки на тарифы</span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="menu-sub menu-sub-dropdown w-175px py-4">

                            <div className="menu-item px-3">
                                <a href="#"
                                   className="menu-link px-5">ДОБАВИТЬ СПИСОК ВСЕХ ПОДПИСОК ПОЛЬЗОВАТЕЛЯ</a>
                            </div>

                        </div>
                    </div>

                    <div className="separator my-2"></div>

                    <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                         data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                        <a href="#" className="menu-link px-5">
                            <span className="menu-title position-relative">Тема
                                <span className="ms-5 position-absolute translate-middle-y top-50 end-0">
                                    <KTSVG path={'assets/media/icons/duotune/general/gen060.svg'}
                                           className={'theme-light-show svg-icon-2'} />

                                    <KTSVG path={'assets/media/icons/duotune/general/gen061.svg'}
                                           className={'theme-dark-show svg-icon-2'} />
                                </span>
                            </span>
                        </a>
                        <div
                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-color fw-semibold py-4 fs-base w-175px"
                            data-kt-menu="true" data-kt-element="theme-mode-menu">
                            <div className="menu-item px-3 my-0">
                                <a onClick={(ev)=> {
                                    ev.preventDefault();
                                    changeColorTheme('light');
                                }} href="#" className="menu-link px-3 py-2" data-kt-element="mode"
                                   data-kt-value="light">
                                    <span className="menu-icon" data-kt-element="icon">
                                        <KTSVG path={'assets/media/icons/duotune/general/gen060.svg'}
                                               className={'svg-icon-3'} />
                                    </span>
                                    <span className="menu-title">Светлая</span>
                                </a>
                            </div>

                            <div className="menu-item px-3 my-0">
                                <a onClick={(ev)=> {
                                    ev.preventDefault();
                                    changeColorTheme('dark');
                                }} href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
                                    <span className="menu-icon" data-kt-element="icon">
                                        <KTSVG path={'assets/media/icons/duotune/general/gen061.svg'}
                                               clasName={'svg-icon-3'} />
                                    </span>
                                    <span className="menu-title">Темная</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="menu-item px-5 my-1">
                        <a href="#" className="menu-link px-5">
                            Настройка аккаунта
                        </a>
                    </div>

                    <div className="menu-item px-5">
                        <a href="#"
                           onClick={()=> {
                               props.changeToken('');
                           }
                           }
                           className="menu-link px-5">Выход</a>
                    </div>

                </div>
            </div>

        </div>
    );
};

// const selectAllSubscribesTariff = (merchant) => {
//
//     const tariffs = [];
//     merchant.storesKaspi.forEach(
//         (el, ind) => {
//             if (el?.tariff) {
//                 tariffs.push(
//                     <div key={ind} className="menu-item px-3">
//                         <a href="#"
//                            className="menu-link px-5">{el.name} - {el.tariff.tariff.name}</a>
//                     </div>
//                 )
//             }
//         }
//     )
//
//     return tariffs;
//
// }

export default SidebarLogo;