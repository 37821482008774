import { connect } from "react-redux";
import {FUNCTION_MARKETPLACE} from "../../../reducers/Marketplace";
import {ContentKaspi} from "../../../components/content_container/kaspi/ContentKaspi";

function mapStateToProps(state) {
    const { UserJWT, Marketplace } = state;
    return { UserJWT, Marketplace }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE()
    return {
        'changeMarketplaceStores': (stores) => dispatch(funcDispatch.changeMarketplaceStores(stores)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentKaspi);