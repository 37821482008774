import initialState from "../initialState";

export const EditOfferField =function(state = initialState.EditOfferField, action) {
    switch (action.type) {
        case EDIT_OFFER_FIELD.CHANGE_FIELD:
            return {
                ...initialState.EditOfferField,
                ...action.offer
            }
        default:
            return state
    }
}

export const EDIT_OFFER_FIELD = {
    CHANGE_FIELD : "CHANGE_FIELD",
}

export class FUNCTION_EDIT_OFFER_FIELD {
    changeFieldOffer = (offer) => {
        return { type: EDIT_OFFER_FIELD.CHANGE_FIELD , offer}
    }
}