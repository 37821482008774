import React from 'react';
import StoreSettingsKaspi from "../../../../conteiners/content_container/kaspi/detail_store/store_settings/StoreSettingsKaspi";
import OffersKaspi from "./store_offers/OffersKaspi";

const DetailStoreKaspi = (props) => {

    switch (props.Marketplace.detailName) {
        case 'settings':
            return <StoreSettingsKaspi />
        case 'offers':
            return <OffersKaspi />
        default:
            return null
    }
};

export default DetailStoreKaspi;