import React from 'react';
import SidebarFooter from "./sidebar_footer/SidebarFooter";
import SidebarNav from "../../conteiners/app_sidebar/sidebar_nav/SidebarNav";
import SidebarLogo from "../../conteiners/app_sidebar/sidebar_logo/SidebarLogo";

const AppSidebar = (props) => {
    return (
        <div id="mp_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true"
             data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}"
             data-kt-drawer-overlay="true" data-kt-drawer-width="275px" data-kt-drawer-direction="start"
             data-kt-drawer-toggle="#mp_app_sidebar_toggle">

            <SidebarLogo />
            <SidebarNav />
            <SidebarFooter />

        </div>
    );
};

export default AppSidebar;