import React from 'react';
import {KTSVG} from "../../KTSVG";

const Navbar = () => {

    return (
        <div className="app-navbar flex-shrink-0">
            <div className="app-navbar-item ms-1 ms-lg-3">
                <div
                    className="btn btn-icon btn-circle btn-color-gray-500 btn-active-color-primary btn-custom shadow-xs bg-body"
                    id="mp_drawer_chat_toggle">
                    <i className="fonticon-messenger fs-2"></i>
                </div>
            </div>

            <div className="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
                <div className="btn btn-icon btn-color-gray-600 btn-active-color-primary"
                     id="mp_app_header_menu_toggle">
                    <KTSVG path={'assets/media/icons/duotune/text/txt001.svg'}
                           className={'svg-icon-2'} />
                </div>
            </div>
        </div>
    );
};

export default Navbar;