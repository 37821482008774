import { connect } from "react-redux";
import {FUNCTION_EDIT_OFFER_FIELD} from "../../../../../reducers/EditOfferField";
import EditOfferModal from "../../../../../components/content_container/kaspi/detail_store/store_offers/EditOfferModal";
import {FUNCTION_DATA_OFFERS} from "../../../../../reducers/DataOffers";


function mapStateToProps(state) {
    const { UserJWT, EditOfferField, SelectedStore, DataOffers } = state;
    return { UserJWT, EditOfferField, SelectedStore, DataOffers }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_EDIT_OFFER_FIELD();
    const funcDispatchDataOffers = new FUNCTION_DATA_OFFERS();
    return {
        'changeFieldOffer': (offer) => dispatch(funcDispatch.changeFieldOffer(offer)),
        'changeDataOffers': (dataOffers) => dispatch(funcDispatchDataOffers.changeDataOffers(dataOffers)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditOfferModal);