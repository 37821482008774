import React from 'react';
import SignIn from "../../conteiners/authentication/sign_in/SignIn";
import SignUp from "../../conteiners/authentication/sign_up/SignUp";
import Landing from "../../conteiners/landing/Landing";

const Sign = (props) => {

    switch (props.AuthenticationTypePage) {
        case 'signIn':
            return <SignIn />
        case '':
            return <Landing />
        default:
            return <SignUp />
    }

};

export default Sign;