import AdvancedSearchKaspi from "./components/content_container/kaspi/detail_store/store_offers/AdvancedSearchKaspi";

export default {
    MarketplaceList: [],

    UserJWT: "",

    AuthenticationTypePage: "",

    Marketplace: {
        name: '',
        stores: [],
        detailName: 'settings',



        detailInformation: {
            action: 'settings',
            dataStore: [],
            dataOffers: [],
            dataOrders: [],
            search: {
                searchData: [],
                searchString: '',
                searchDataString: '',
            },
        },
        singleStore: {
            login: '',
            password: '',
            token: '',
            name: '',
            active: false,

            activeCount: '',
            lastUpdate: '',
            limitCountItems: '',
            offerUrl: '',
            dumpingBrands: '',
            hybrisUid: '',
            offersExpiredNotificationText: '',
            stepDumping: '',
            dumping: false,
            updateStoreData: false
        },
    },

    SelectedStore: {},

    AdvancedSearchKaspi: {
        searchString: '',
        searchBrands: [],
        offerStatus: 'ACTIVE',
        place: undefined,
        notMinPrice: false,
        notMaxPrice: false,
        dumping: undefined,
        controlBalance: undefined,
        resetPagination: false
    },

    DataOffers: [],

    EditOfferField: {},

    Pagination: {
        itemsPerPage: 10,
        totalCountItems: 0,
        currentPage: 1,
    },
}