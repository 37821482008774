import { connect } from "react-redux";
import AdvancedSearchKaspi from "../../../../../components/content_container/kaspi/detail_store/store_offers/AdvancedSearchKaspi";
import {FUNCTION_SEARCH_OFFERS_KASPI} from "../../../../../reducers/AdvancedSearchKaspi";
import {FUNCTION_DATA_OFFERS} from "../../../../../reducers/DataOffers";
import {FUNCTION_PAGINATION} from "../../../../../reducers/Pagination";


function mapStateToProps(state) {
    const { UserJWT, AdvancedSearchKaspi, SelectedStore, Pagination } = state;
    return { UserJWT, AdvancedSearchKaspi, SelectedStore, Pagination }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_SEARCH_OFFERS_KASPI();
    const funcDispatchDataOffers = new FUNCTION_DATA_OFFERS();
    const funcDispatchPagination = new FUNCTION_PAGINATION();
    return {
        'changeSearchString': (searchString) => dispatch(funcDispatch.changeSearchString(searchString)),
        'changeSearchBrands': (searchBrands) => dispatch(funcDispatch.changeSearchBrands(searchBrands)),
        'changeSearchOfferStatus': (offerStatus) => dispatch(funcDispatch.changeSearchOfferStatus(offerStatus)),
        'changeSearchPlace': (place) => dispatch(funcDispatch.changeSearchPlace(place)),
        'changeSearchNotMinPrice': (notMinPrice) => dispatch(funcDispatch.changeSearchNotMinPrice(notMinPrice)),
        'changeSearchNotMaxPrice': (notMaxPrice) => dispatch(funcDispatch.changeSearchNotMaxPrice(notMaxPrice)),
        'changeSearchDumping': (dumping) => dispatch(funcDispatch.changeSearchDumping(dumping)),
        'changeSearchControlBalance': (controlBalance) => dispatch(funcDispatch.changeSearchControlBalance(controlBalance)),
        'changeResetPagination': (resetPagination) => dispatch(funcDispatch.changeResetPagination(resetPagination)),
        'changeCleanSearch': () => dispatch(funcDispatch.changeCleanSearch()),
        'changeDataOffers': (dataOffers) => dispatch(funcDispatchDataOffers.changeDataOffers(dataOffers)),
        'setTotalCountItems': (value) => dispatch(funcDispatchPagination.setTotalCountItems(value)),
        'setFirstState': (value) => dispatch(funcDispatchPagination.setFirstState(value)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchKaspi);