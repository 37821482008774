import React from 'react';
import Landing from "../conteiners/landing/Landing";
import Header from "./header/Header";
import ContentMarketplace from "../conteiners/ContentMarketplace";
import AppSidebar from "./app_sidebar/AppSidebar";
import Footer from "./footer/Footer";

const PageContent = (props) => {

    if (props.UserJWT === '') return <Landing />

    return (
        <div className="d-flex flex-column flex-root app-root" id="mp_app_root">
            <div className="app-page flex-column flex-column-fluid" id="mp_app_page">

                <Header />

                <div className="app-wrapper flex-column flex-row-fluid" id="mp_app_wrapper">

                    <AppSidebar />

                    <div className="app-main flex-column flex-row-fluid" id="mp_app_main">

                        <ContentMarketplace />

                        <Footer />

                    </div>

                </div>
            </div>
        </div>
    );
};


export default PageContent;