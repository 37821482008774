import { connect } from "react-redux";
import {FUNCTION_MARKETPLACE} from "../../../../../reducers/Marketplace";
import StoreSettingsSber
    from "../../../../../components/content_container/sber/detail_store/store_settings/StoreSettingsSber";


function mapStateToProps(state) {
    const { Merchant, Marketplace } = state;
    return { Merchant, Marketplace }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE()
    return {
        'changeLogin': (value) => dispatch(funcDispatch.changeMarketplaceSingleStoreLogin(value)),
        'changePassword': (value) => dispatch(funcDispatch.changeMarketplaceSingleStorePassword(value)),
        'changeToken': (value) => dispatch(funcDispatch.changeMarketplaceSingleStoreAuthToken(value)),
        'changeSingleStore': (singleStore) => dispatch(funcDispatch.changeMarketplaceSingleStore(singleStore)),
        'changeName': (value) => dispatch(funcDispatch.changeMarketplaceSingleStoreName(value)),
        'changeActive': (value) => dispatch(funcDispatch.changeMarketplaceSingleStoreActive(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSettingsSber);