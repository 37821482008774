import React from 'react'
import SVG from 'react-inlinesvg'

const KTSVG = function (props) {

    const svgClassName = (!props.svgClassName) ? 'mh-50px' : props.svgClassName;

    return (
        <span className={`svg-icon ${props.className}`}>
            <SVG src={props.path} className={svgClassName}/>
        </span>
    )
}

export {KTSVG}