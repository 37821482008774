import {KTSVG} from "./KTSVG";
import jwt_decode from "jwt-decode";

export const getHeaderData = (method = 'GET', data = null, JWT = '') => {

    const headersObject = {
        method,
        headers: {
            "Content-type": "application/json",
            "WWW-Authenticate": JWT,
        },
    };

    if (data) headersObject.body = JSON.stringify(data);

    return headersObject;
}

export const formatSumma = (summa) => new Intl.NumberFormat('ru-RU').format(summa)

export const URL_DATA_BASE = 'https://api.topsale.guru'
// export const URL_DATA_BASE = 'http://127.0.0.1:8000'

export const updateUserData = (url, headerObject, setResponseBody) => {
    fetch(url, headerObject)
        .then(resp => resp.json())
        .then(body => setResponseBody(body))
        .catch(error => console.log(error))
}


export const getJWT_Token = () => {
    return window.localStorage.getItem('mp_jwt_user');
}

export const setJWT_Token = (jwtToken) => {
    window.localStorage.setItem('mp_jwt_user', jwtToken);
}

export const clearJWT_Token = () => {
    window.localStorage.removeItem('mp_jwt_user');
}

export const blockInformationNoStore = () => {
    return (
        <div
            className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

            <KTSVG path={'assets/media/icons/duotune/general/gen044.svg'}
                   className={'svg-icon-5tx svg-icon-danger mb-5'}/>

            <div className="text-center">
                <h1 className="fw-bold mb-5">Нет добавленных магазинов по данному маркетплейсу.</h1>

                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                <div className="mb-9 text-dark">
                    Самое время начать с добавления первого 😉
                </div>

            </div>

        </div>
    )
}

export const currentStoreListByMarketplaceName = (marketplaceName, Merchant) => {

    switch (marketplaceName) {
        case 'kaspi':
            return Merchant.storesKaspi;
        default:
            return []
    }
}

export const expireDateTariff = (endTime) => {

    // Берем разницу дат в секундах
    let delta = Math.floor((endTime - new Date()) / 1000);
    if (delta <= 0) {
        return `${0} д. ${0} ч. ${0} мин.`; // ${seconds} сек.`;
    }
    // Вычисляем количество ПОЛНЫХ дней
    let days = Math.floor(delta / 86400);
    // А теперь вычитаем из секунд количество дней, выраженных в секундах
    delta -= days * 86400;
    // В оставшихся секунд вычленяем количество полных часов
    let hours = Math.floor(delta / 3600) % 24;
    // Также их потом вычитаем, выразив в секундах
    delta -= hours * 3600;
    // Из оставшихся секунд берем минуты
    let minutes = Math.floor(delta / 60) % 60;
    // Опять вычитаем
    delta -= minutes * 60;
    // И наконец секунды
    // В теории  деление по модулю на 60 не обязателен
    let seconds = delta % 60;
    // Итоговая дата
    let dateStr = `${days} д. ${hours} ч. ${minutes} мин.`; // ${seconds} сек.`;

    return dateStr

}

export const dataUserJwtDecoded = (JWT) => jwt_decode(JWT);


export const showPageLoadingTimeout = (showTime) => {
    const loadingEl = document.createElement("div");
    document.body.prepend(loadingEl);
    loadingEl.classList.add("page-loader");
    loadingEl.classList.add("flex-column");
    loadingEl.classList.add("bg-dark");
    loadingEl.classList.add("bg-opacity-25");
    loadingEl.innerHTML = `
    <span class="spinner-border text-primary" role="status"></span>
    <span class="text-gray-800 fs-6 fw-semibold mt-5">Загрузка...</span>
`;

    // Show page loading
    window.KTApp.showPageLoading();

    // Hide after 3 seconds
    setTimeout(function() {
        window.KTApp.hidePageLoading();
        loadingEl.remove();
    }, showTime);
}