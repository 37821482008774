import React from 'react';
import {KTSVG} from "./KTSVG";
import {showPageLoadingTimeout} from "./settings";

const Pagination = (props) => {

    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(props.Pagination.totalCountItems / props.Pagination.itemsPerPage); i++) {
        pageNumbers.push(i)
    }

    const previewPageNumbers = () => {
        const maxItemsPerPage = 6;

        if (props.Pagination.totalCountItems <= maxItemsPerPage) return pageNumbers;
        let endIndex = maxItemsPerPage;
        if (props.Pagination.currentPage > 3) {
            endIndex = ((props.Pagination.currentPage + (props.Pagination.currentPage)) > props.Pagination.totalCountItems ? props.Pagination.totalCountItems : (props.Pagination.currentPage + 3));
        }
        return pageNumbers.slice(endIndex - maxItemsPerPage, endIndex)
    }

    const prevPage = () => {
        props.setCurrentPage(props.Pagination.currentPage - 1);
    }

    const nextPage = () => {
        props.setCurrentPage(props.Pagination.currentPage + 1);
    }

    return (
        <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="dataTables_length" id="mp_ecommerce_products_table_length">
                    <label>
                        <select onChange={(ev) => props.setItemsPerPage(parseInt(ev.target.options[ev.target.selectedIndex].value))}
                                value={props.Pagination.itemsPerPage}
                            name="mp_ecommerce_products_table_length" aria-controls="mp_ecommerce_products_table"
                            className="form-select form-select-sm form-select-solid">
                            {[10, 25, 50].map((el, ind)=> {
                                return (
                                    <option key={ind} value={el}>{el}</option>
                                )
                            })}
                        </select>
                    </label>
                </div>
            </div>

            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div className="dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">

                        <li className={`page-item first m-1${(props.Pagination.currentPage === 1) ? ' disabled' : ''}`}>
                            <a href="#" onClick={()=> props.setCurrentPage(1)} className="page-link px-0">
                                <KTSVG path={'assets/media/icons/duotune/arrows/arr079.svg'}
                                       className={'svg-icon-2'} />
                            </a>
                        </li>

                        <li className={`page-item previous m-1${(props.Pagination.currentPage === 1) ? ' disabled' : ''}`}
                            id="kt_ecommerce_products_table_previous">
                            <a onClick={prevPage} href="#"
                               aria-controls="kt_ecommerce_products_table"
                               data-dt-idx="0" tabIndex="0"
                               className="page-link">
                                <i className="previous"></i>
                            </a>
                        </li>

                        {previewPageNumbers().map((number, ind) => {
                            return (
                                <li key={ind} className={`page-item m-1${(props.Pagination.currentPage === number ? ' active' : '')}`}>
                                    <a href="#"
                                       onClick={()=> props.setCurrentPage(number)}
                                       aria-controls="kt_ecommerce_products_table"
                                       data-dt-idx="1" tabIndex="0"
                                       className="page-link">{number}
                                    </a>
                                </li>
                            )
                        })}

                        <li className={`page-item next m-1${(props.Pagination.currentPage === pageNumbers.length) ? ' disabled' : ''}`}
                            id="kt_ecommerce_products_table_next">
                            <a onClick={nextPage} href="#" aria-controls="kt_ecommerce_products_table" data-dt-idx="6" tabIndex="0"
                                className="page-link">
                                <i className="next"></i>
                            </a>
                        </li>

                        <li className={`page-item last m-1${(props.Pagination.currentPage === pageNumbers.length) ? ' disabled' : ''}`}>
                            <a onClick={()=> props.setCurrentPage(pageNumbers.length)} href="#" className="page-link px-0">
                                <KTSVG path={'assets/media/icons/duotune/arrows/arr080.svg'}
                                       className={'svg-icon-2'} />
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Pagination;