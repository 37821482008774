import { connect } from "react-redux";
import {FUNCTION_MARKETPLACE} from "../../../reducers/Marketplace";
import ContentSber from "../../../components/content_container/sber/ContentSber";


function mapStateToProps(state) {
    const { Merchant, Marketplace } = state;
    return { Merchant, Marketplace }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_MARKETPLACE()
    return {
        'changeMarketplaceName': (value) => dispatch(funcDispatch.changeMarketplaceName(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSber);