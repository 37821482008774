import React, {useEffect} from 'react';

const MenuWrapper = () => {

    useEffect(()=> {
        window.KTDrawer.createInstances();
    }, [])

    return (
        <div className="app-header-menu app-header-mobile-drawer align-items-start align-items-lg-center w-100"
             data-kt-drawer="true" data-kt-drawer-name="app-header-menu"
             data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
             data-kt-drawer-width="250px" data-kt-drawer-direction="end"
             data-kt-drawer-toggle="#mp_app_header_menu_toggle" data-kt-swapper="true"
             data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
             data-kt-swapper-parent="{default: '#mp_app_body', lg: '#mp_app_header_wrapper'}">
            <div
                className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-400 menu-bullet-gray-400 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
                id="#mp_header_menu" data-kt-menu="true">



                <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
                     className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
                    <span className="menu-link">
                        <span className="menu-title">Help</span>
                        <span className="menu-arrow d-lg-none"></span>
                    </span>
                    <div
                        className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">
                        <div className="menu-item">
                            <a className="menu-link"
                               href="https://preview.keenthemes.com/html/metronic/docs/base/utilities" target="blank"
                               title="Check out over 200 in-house components, plugins and ready for use solutions"
                               data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click"
                               data-bs-placement="right">
					<span className="menu-icon">
						<i className="bi bi-bookmarks-fill fs-3"></i>
					</span>
                                <span className="menu-title">Components</span>
                            </a>
                        </div>
                        <div className="menu-item">
                            <a className="menu-link" href="https://preview.keenthemes.com/html/metronic/docs"
                               target="blank" title="Check out the complete documentation" data-bs-toggle="tooltip"
                               data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
					<span className="menu-icon">
						<i className="bi bi-box fs-3"></i>
					</span>
                                <span className="menu-title">Documentation</span>
                            </a>
                        </div>
                        <div className="menu-item">
                            <a className="menu-link" href="https://preview.keenthemes.com/metronic8/layout-builder.html"
                               title="Build your layout and export HTML for server side integration"
                               data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click"
                               data-bs-placement="right">
					<span className="menu-icon">
						<i className="bi bi-sliders fs-3"></i>
					</span>
                                <span className="menu-title">Layout Builder</span>
                            </a>
                        </div>
                        <div className="menu-item">
                            <a className="menu-link"
                               href="https://preview.keenthemes.com/html/metronic/docs/getting-started/changelog"
                               target="blank">
					<span className="menu-icon">
						<i className="bi bi-diagram-3 fs-3"></i>
					</span>
                                <span className="menu-title">Changelog v8.1.5</span>
                            </a>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    );
};

export default MenuWrapper;