import React from 'react';
import ToolbarHeaderStore from "../../../conteiners/content_container/ToolbarHeaderStore";
import DetailStoreSber from "./detail_store/DetailStoreSber";

const StoresSber = (props) => {

    if (!props.Marketplace.singleStore?.login) return null;

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card card-flush">

                    <ToolbarHeaderStore />

                    <DetailStoreSber action={props.Marketplace.detailInformation.action}/>

                </div>
            </div>
        </div>
    );
};

export default StoresSber;