import initialState from "../initialState";

export const DataOffers =function(state = initialState.DataOffers, action) {
    switch (action.type) {
        case DATA_OFFERS.CHANGE_DATA_OFFERS:
            return action.dataOffers
        default:
            return state
    }
}

export const DATA_OFFERS = {
    CHANGE_DATA_OFFERS : "CHANGE_DATA_OFFERS",
}

export class FUNCTION_DATA_OFFERS {
    changeDataOffers = (dataOffers) => {
        return { type: DATA_OFFERS.CHANGE_DATA_OFFERS , dataOffers}
    }
}