import React, {useEffect} from 'react';
import {KTSVG} from "./KTSVG";

const ScrollTop = () => {

    useEffect(() => {
        window.KTScrolltop.createInstances();
    }, [])

    return (
        <div id="mp_scrolltop" className="scrolltop" data-kt-scrolltop="true">
            <KTSVG path={'assets/media/icons/duotune/arrows/arr066.svg'} />
        </div>
    );
};

export default ScrollTop;