import React from 'react';
import {
    dataUserJwtDecoded,
    getHeaderData,
    URL_DATA_BASE
} from "../../settings";
import ToolbarMarket from "../../../conteiners/content_container/ToolbarMarket";
import ModalCreateStore from "../../../conteiners/modal_window/ModalCreateStore";
import StoresKaspi from "../../../conteiners/content_container/kaspi/StoresKaspi";

const ContentKaspi = (props) => {

    const updateMerchantData = () => {
        fetch(`${URL_DATA_BASE}/${props.Marketplace.name}/store/list`, getHeaderData("GET", null, props.UserJWT))
                .then(resp => resp.json())
                .then(body => {
                    if (!body?.detail) {
                        props.changeMarketplaceStores(body);
                    }
                })
                .catch(error => console.log(error))
    }
    const dataUser = dataUserJwtDecoded(props.UserJWT);
    const dataFieldModalStoreCreate = {
        login: {title: 'Эл. почта', type: "email", placeholder: 'Укажите логин', required: true},
        password: {title: 'Пароль', type: 'password', placeholder: 'Укажите пароль', required: true},
        userId: {type: 'text', hidden: true, value: dataUser.id},
        name: {type: 'text', hidden: true, value: props.Marketplace.name},
    }

    return (
        <div className="d-flex flex-column flex-column-fluid">
            <div id="mp_app_content" className="app-content flex-column-fluid">
                <div id="mp_app_content_container" className="app-container container-xxl">
                {/*<div id="mp_app_content_container" className="app-container container-fluid">*/}

                    <ToolbarMarket />

                    <StoresKaspi />

                </div>
            </div>

             <ModalCreateStore
                 updateMerchantData={updateMerchantData}
                 data={dataFieldModalStoreCreate}
             />

        </div>
    )

};

export {ContentKaspi};