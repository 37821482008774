import { connect } from "react-redux";
import Pagination from "../components/Pagination";
import {FUNCTION_PAGINATION} from "../reducers/Pagination";

function mapStateToProps(state) {
    const { Pagination, DataOffers } = state;
    return { Pagination, DataOffers }
}

function mapDispatchToProps(dispatch) {
    const funcDispatch = new FUNCTION_PAGINATION();
    return {
        'setCurrentPage': (value) => dispatch(funcDispatch.setCurrentPage(value)),
        'setItemsPerPage': (value) => dispatch(funcDispatch.setItemsPerPage(value)),
        'setTotalCountItems': (value) => dispatch(funcDispatch.setTotalCountItems(value)),
        'setFirstState': (value) => dispatch(funcDispatch.setFirstState(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);