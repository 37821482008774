import initialState from "../initialState";

export const SelectedStore =function(state = initialState.SelectedStore, action) {
    switch (action.type) {
        case SELECTED_STORE.CHANGE_STORE:
            return {
                ...action.store,
                marketplaceName: action.name
            }
        default:
            return state
    }
}

export const SELECTED_STORE = {
    CHANGE_STORE : "CHANGE_STORE",
}

export class FUNCTION_SELECTED_STORE {
    changeStore = (store, name) => {
        return { type: SELECTED_STORE.CHANGE_STORE, store, name }
    }
}