import React, {useEffect} from 'react';
import {KTSVG} from "../../../../KTSVG";
import {getHeaderData, URL_DATA_BASE} from "../../../../settings";

const AdvancedSearchKaspi = (props) => {

    useEffect(()=>{
        const elDom = document.querySelector('#filter_brands');
        const updateBrandsList = async () => {
            const resp = await fetch(`${URL_DATA_BASE}/${props.SelectedStore.marketplaceName}/offer/filter/${props.SelectedStore.id}`,
                getHeaderData('GET', null, props.UserJWT));
            const body = await resp.json();
            let brands = []
            if (!body?.detail) {
                brands = body;
            }
            brands = brands.filter(el=> el.name !== null);
            new window.Tagify(elDom, {
                whitelist: brands,
                maxTags: 10,
                enforceWhitelist: true,
                dropdown: {
                    maxItems: 20,           // <- mixumum allowed rendered suggestions
                    classname: "", // <- custom classname for this dropdown, so it could be targeted
                    enabled: 0,             // <- show suggestions on focus
                    closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
                }
            });

        }
        updateBrandsList().catch(error => console.log(error));
        const elCheckedPlace = document.querySelector('input[mp-place=""]');
        elCheckedPlace.checked = true;
        return function () {
            props.changeCleanSearch();
        }
    }, []);

    useEffect(()=> {
        postOffersKaspi().catch(error=> console.log(error));
    }, [props.Pagination.currentPage, props.Pagination.itemsPerPage])

    const postOffersKaspi = async function () {
        const data = {
            filter: props.AdvancedSearchKaspi,
            offset: props.AdvancedSearchKaspi.resetPagination ? 0 : (props.Pagination.itemsPerPage * (props.Pagination.currentPage - 1)),
            limit: props.Pagination.itemsPerPage
        }
        const resp = await fetch(`${URL_DATA_BASE}/${props.SelectedStore.marketplaceName}/offer/list/${props.SelectedStore.id}`,
            getHeaderData('POST', data, props.UserJWT));
        const body = await resp.json();
        if (resp.status === 200) {
            props.changeDataOffers(body?.offers || []);
            if (props.AdvancedSearchKaspi.resetPagination) {
                props.setFirstState(body?.total || 0);
            } else {
                props.setTotalCountItems(body?.total || 0);
            }
            props.changeResetPagination(false);
        }
    }

    const changeBrandsSearch = (valueData) => {
        let valueDataString = [];
        if (valueData) {
            valueData = JSON.parse(valueData);
            valueData.forEach(el => {
                valueDataString.push(el.value);
            })
        }

        const isEqual = JSON.stringify(valueDataString) === JSON.stringify(props.AdvancedSearchKaspi.searchBrands);
        if (!isEqual) {
            props.changeSearchBrands(valueDataString);
        }
    }

    return (
        <div className="card mb-7">
            <div className="card-body">

                <div className="d-flex align-items-center">
                    <div className="position-relative w-md-400px me-md-2">
                        <KTSVG path={'assets/media/icons/duotune/general/gen021.svg'}
                               className={'svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6'}
                        />
                        <input type="text"
                               onChange={(ev)=> props.changeSearchString(ev.target.value) }
                               className="form-control form-control-solid ps-10"
                               name="search"
                               value={props.AdvancedSearchKaspi.searchString}
                               placeholder="Поиск товара"/>
                    </div>

                    <div className="d-flex align-items-center">
                        <button onClick={postOffersKaspi} type="submit" className="btn btn-primary me-5">Поиск</button>
                        <a id="kt_horizontal_search_advanced_link" className="btn btn-link" data-bs-toggle="collapse"
                           href="#mp_advanced_search_form">Расширенный поиск</a>
                    </div>
                </div>

                <div className="collapse" id="mp_advanced_search_form">
                    <div className="separator separator-dashed mt-9 mb-6"></div>

                    <div className="row g-8 mb-8">
                        <div className="col-xxl-6">
                            <label className="fs-6 form-label fw-bold text-dark">Бренд</label>
                            <input type="text" className="form-control form-control form-control-solid"
                                   id={'filter_brands'}
                                   onChange={ev=>changeBrandsSearch(ev.target.value)}
                                   name="brand"
                                   value={props.AdvancedSearchKaspi.searchBrands.toString()}/>
                        </div>

                        <div className="col-xxl-6">
                            <div className="row g-8">
                                <div className="col-lg-6">
                                    <label className="fs-6 form-label fw-bold text-dark">Статус</label>
                                    <select onChange={ev=>props.changeSearchOfferStatus(ev.target.options[ev.target.selectedIndex].value)}
                                            className="form-select form-select-solid" id={'search_filter'} data-control="select2"
                                            data-placeholder="In Progress" data-hide-search="true">
                                        <option value="ACTIVE">Опубликованные</option>
                                        <option value="PROCESSING">Ожидают применения изменений</option>
                                        <option value="EXPIRING">Истекает срок размещения</option>
                                        <option value="ARCHIVE">Архивные</option>
                                    </select>
                                </div>

                                <div className="col-lg-6">
                                    <label className="fs-6 form-label fw-bold text-dark">Место</label>
                                    <div className="nav-group nav-group-fluid">
                                        <label>
                                            <input mp-place={''} onClick={ev=>props.changeSearchPlace(undefined)}
                                                   type="radio" className="btn-check" name="type" value=""/>
                                            <span title={"Все"}
                                                className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">Все</span>
                                        </label>

                                        <label>
                                            <input mp-place={'0'} onClick={ev=>props.changeSearchPlace(0)}
                                                   type="radio" className="btn-check" name="type" value="0"/>
                                            <span title={"Без места"}
                                                className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">?</span>
                                        </label>

                                        <label>
                                            <input mp-place={'1'} onClick={ev=>props.changeSearchPlace(1)}
                                                   type="radio" className="btn-check" name="type" value="1"/>
                                            <span title={"Первое"}
                                                className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">1</span>
                                        </label>

                                        <label>
                                            <input mp-place={'2'} onClick={ev=>props.changeSearchPlace(2)}
                                                   type="radio" className="btn-check" name="type" value="2"/>
                                            <span title={"Второе"}
                                                className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">2</span>
                                        </label>
                                        <label>
                                            <input mp-place={'3'} onClick={ev=>props.changeSearchPlace(3)}
                                                   type="radio" className="btn-check" name="type" value="3"/>
                                            <span title={"Третье"}
                                                className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">3</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-8">
                        <div className="col-xxl-7">
                            <div className="row g-8">
                                <div className="col-lg-6">
                                    <label className="fs-6 form-label fw-bold text-dark">Без минимальной цены</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid mt-1">
                                        <input className="form-check-input" type="checkbox" value=""
                                               onChange={()=>props.changeSearchNotMinPrice(!props.AdvancedSearchKaspi.notMinPrice)}
                                               id="not_min_price" checked={props.AdvancedSearchKaspi.notMinPrice}/>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <label className="fs-6 form-label fw-bold text-dark">Без максимальной цены</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid mt-1">
                                        <input className="form-check-input" type="checkbox" value=""
                                               onChange={()=>props.changeSearchNotMaxPrice(!props.AdvancedSearchKaspi.notMaxPrice)}
                                               id="not_max_price" checked={props.AdvancedSearchKaspi.notMaxPrice}/>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-xxl-5">
                            <div className="row g-8">
                                <div className="col-lg-6">
                                    <label className="fs-6 form-label fw-bold text-dark">Демпинг</label>
                                    <select onChange={ev=>props.changeSearchDumping(ev.target.options[ev.target.selectedIndex].value)}
                                            className="form-select form-select-solid" data-control="select2"
                                            data-placeholder="In Progress" data-hide-search="true">
                                        <option value="">Все</option>
                                        <option value="1">Демпинг включен</option>
                                        <option value="0">Демпинг выключен</option>
                                    </select>
                                </div>

                                <div className="col-lg-6">
                                    <label className="fs-6 form-label fw-bold text-dark">Контроль остатков</label>
                                    <select onChange={ev=>props.changeSearchControlBalance(ev.target.options[ev.target.selectedIndex].value)}
                                            className="form-select form-select-solid" data-control="select2"
                                            data-placeholder="In Progress" data-hide-search="true">
                                        <option value="">Все</option>
                                        <option value="1">Контроль остатков включен</option>
                                        <option value="0">Контроль остатков выключен</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AdvancedSearchKaspi;