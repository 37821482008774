import React from 'react';
import {KTSVG} from "../../KTSVG";

const LogoWrapper = () => {
    return (
        <div className="d-flex align-items-center">
            <div className="btn btn-icon btn-color-gray-600 btn-active-color-primary ms-n3 me-2 d-flex d-lg-none"
                 id="mp_app_sidebar_toggle">
                <KTSVG path={'assets/media/icons/duotune/abstract/abs015.svg'}
                       className={'svg-icon-2'} />
            </div>

            <a href="#" className="d-flex d-lg-none">
                <img alt="Logo" src="assets/media/logos/TSG.svg" className="h-30px theme-light-show"/>
                <img alt="Logo" src="assets/media/logos/TSG-dark.svg" className="h-30px theme-dark-show"/>
            </a>
        </div>
    );
};

export default LogoWrapper;