import { connect } from "react-redux";
import ModalCreateStore from "../../components/modal_windows/ModalCreateStore";


function mapStateToProps(state) {
    const { UserJWT, Marketplace } = state;
    return { UserJWT, Marketplace }
}


export default connect(mapStateToProps)(ModalCreateStore);