import React, {useEffect, useState} from 'react';
import {KTSVG} from "../../../../KTSVG";
import {formatSumma, getHeaderData, URL_DATA_BASE} from "../../../../settings";
import 'animate.css';

const EditOfferModal = (props) => {

    const [offerField, setOfferField] = useState({updateOffer: false});

    useEffect(()=> {
        if (Object.keys(props.EditOfferField).length) {
            setOfferField({
                updateOffer: false,
                ...Object.assign({}, props.EditOfferField)
            });
            const dumpingEl = document.querySelector('#mp_modal_edit_offer_dumping');
            dumpingEl.checked = props.EditOfferField.dumping;
        }
    }, [props.EditOfferField])

    const setDumping = () => {
        document.querySelector('#mp_modal_edit_offer_dumping').checked = !offerField.dumping;
        setOfferField({
            ...offerField,
            updateOffer: true,
            dumping: !offerField.dumping
        })
    }

    const isArchive = offerField?.offerStatus === 'ARCHIVE';
    const isProcessing = offerField?.offerStatus === 'PROCESSING';

    const clearEditField = () => {
        props.changeFieldOffer({});
    }

    const closeModal= () => {
        clearEditField();
        window.$('#mp_modal_edit_offer').modal('hide');
    }

    const statusField = (offerStatus)=> {
        switch (offerStatus){
            case 'ACTIVE':
                return 'Опубликованный'
            case 'ARCHIVE':
                return 'В архиве'
            case 'PROCESSING':
                return 'Применение изменений'
            case 'EXPIRING':
                return 'Истекает срок размещения'
            default:
                return ''
        }
    }

    const dumpingStore = props.SelectedStore.dumping;
    const editPriceDumping = (dumpingStore && offerField?.dumping)

    function editPrice(ev, cityId) {
        const rowPrice = offerField.cities.filter(el => el.city.cityId === cityId)[0];
        const currentPrice = ev.target.value ? parseInt(ev.target.value) : 0;
        const newArrayCities = [];
        offerField.cities.forEach(el=> {
            let rowCity;
            if (el.city.cityId === cityId) {
                rowCity = {
                    ...el,
                }
                rowCity[ev.target.attributes.name.value] = currentPrice < 0 ? 0 : currentPrice;
            } else {
                rowCity = el;
            }
            newArrayCities.push(rowCity);
        })
        setOfferField({
            ...offerField,
            cities: newArrayCities,
            updateOffer: true,
        });
    }

    const citiesJsx = () => {
        if (!offerField?.cities) return null;
        return offerField.cities.map(
            (el, ind) => {
                return (
                    <tr key={ind}>
                        <td>
                            <div className="symbol symbol-50px me-2">
                                <span
                                    className="symbol-label bg-light-info">
                                    <KTSVG path={'assets/media/icons/duotune/maps/map004.svg'}
                                           className={'svg-icon-2x svg-icon-info'} />
                                </span>
                            </div>
                        </td>
                        <td>
                            <a className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                                {el.city.name}
                            </a>
                            <span className="text-muted fw-semibold d-block">
                                {el.city.cityId}
                            </span>
                        </td>
                        <td className="text-center fw-bold">
                            <span className="badge badge-light-success fs-2">
                                {formatSumma(el.price)}
                            </span>
                        </td>
                        <td className="text-center">

                            <div className="position-relative">
                                <input onChange={(ev)=> editPrice(ev, el.city.cityId)} disabled={!editPriceDumping} type="number"
                                       className="form-control form-control-solid w-100px"
                                       value={el.priceMin}
                                       placeholder="min price" name="priceMin"/>
                            </div>

                        </td>
                        <td className="text-end">

                            <div className="position-relative">
                                <input onChange={(ev)=> editPrice(ev, el.city.cityId)} disabled={!editPriceDumping} type="number"
                                       className="form-control form-control-solid w-100px"
                                       value={el.priceMax}
                                       placeholder="max price" name="priceMax"/>
                            </div>

                        </td>
                    </tr>
                )
            }
        )
    }

    const openSuccessAlert = () => {
        window.Swal.fire({
            title: 'Данные успешно обновлены, продолжить редактирование?',
            showClass: {
                popup: 'animate__animated animate__lightSpeedInLeft'
            },
            hideClass: {
                popup: 'animate__animated animate__lightSpeedOutRight'
            },
            showDenyButton: true,
            confirmButtonText: 'Закрыть',
            denyButtonText: `Продолжить`,
        }).then((result) => {
            if (result.isConfirmed) {
                closeModal();
            } else if (result.isDenied) {
                props.changeFieldOffer(
                    {
                        ...offerField,
                        updateOffer: false
                    }
                )
            }
        })
    }

    const openErrorAlert = () => {
        window.Swal.fire(
          'Не удачная попытка обновления товара',
          'Нажмите на кнопку для продолжения!',
          'error'
        )
    }

    function updateOfferFields() {
        const submitButton = document.querySelector('#mp_modal_edit_offer_submit')
        submitButton.setAttribute('data-kt-indicator', 'on');
        submitButton.disabled = true;
        const removeIndicatorButton = () => {
            submitButton.removeAttribute('data-kt-indicator');
            submitButton.disabled = false;
        }
        const dataFields = {};

        if (offerField.dumping !== props.EditOfferField.dumping) dataFields.dumping = offerField.dumping;
        offerField.cities.forEach((elCit, ind)=> {
            const rowPrice = props.EditOfferField.cities.filter(el=> el.city.cityId === elCit.city.cityId)[0];
            if (elCit.priceMax !== rowPrice.priceMax || elCit.priceMin !== rowPrice.priceMin) {
                if (!dataFields.priceRow) {
                    dataFields.priceRow = [];
                }
                dataFields.priceRow.push(
                    {cityId: elCit.city.cityId, priceMin: elCit.priceMin, priceMax: elCit.priceMax}
                )
            }
        });

        const updateOfferById = async (data) => {
            const resp = await fetch(`${URL_DATA_BASE}/${props.SelectedStore.marketplaceName}/offer/update/${offerField.id}`,
                getHeaderData('POST', data, props.UserJWT));
            const body = await resp.json();
            if (resp.status === 200) {
                setTimeout(removeIndicatorButton, 1000);
                props.changeDataOffers(props.DataOffers.map(el => {
                    if (el.id === body.id) {
                        return body;
                    } else {
                        return el;
                    }
                }))
            }
            return resp.status === 200;
        }

        if (Object.keys(dataFields).length) {

            updateOfferById(dataFields).then(response => {
                response ? openSuccessAlert() : openErrorAlert();
            }).catch(error=> console.log(error));
        }

        // closeModal();
    }

    return (
        <div className="modal fade animate__animated animate__backInDown" id="mp_modal_edit_offer" data-bs-keyboard="false" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content rounded">
                    <div className="modal-header" id="mp_modal_edit_offer_header">
                        <h2 className="fw-bold">
                            Редактировать карточку товара SKU:
                            <span className='text-info'>
                                {offerField?.masterProduct?.sku || ""}
                            </span>
                        </h2>

                        <div id="mp_modal_edit_offer_close"
                             onClick={closeModal}
                             className="btn btn-icon btn-sm btn-active-icon-primary">
                            <KTSVG path={'assets/media/icons/duotune/arrows/arr061.svg'}
                                   className={'svg-icon-1'} />
                        </div>
                    </div>

                    <div className="modal-body py-10 px-lg-17">
                        <div className="scroll-y me-n7 pe-7" id="mp_modal_edit_offer_scroll" data-kt-scroll="true"
                             data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#mp_modal_edit_offer_header"
                             data-kt-scroll-wrappers="#mp_modal_edit_offer_scroll" data-kt-scroll-offset="300px">

                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                <div className="me-7 mb-4">
                                    <div className="symbol symbol-100px symbol-lg-175px symbol-fixed position-relative">
                                        <img src={offerField?.masterProduct?.primaryImage.medium || ''} alt="image" />
                                    </div>
                                </div>
                                <div className="flex-lg-grow-1">
                                    <div className="fv-row mb-5">
                                        <label className="required fs-6 fw-semibold mb-2">Наименование</label>

                                        <input disabled={true} type="text" className="form-control form-control-solid" placeholder=""
                                               name="name" value={offerField?.name || ""}/>
                                    </div>

                                    <div className="fv-row mb-5">
                                        <label className="fs-6 fw-semibold mb-2">
                                            <span className="required">Бренд</span>
                                            {/*<i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip"*/}
                                            {/*   title="Email address must be active"></i>*/}
                                        </label>

                                        <input disabled={true} type="text" className="form-control form-control-solid" placeholder=""
                                               name="brand" value={offerField?.brand || ""}/>
                                    </div>
                                </div>

                            </div>

                            <div className="row g-9 mb-7">
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-6 fw-semibold mb-2">Статус</label>

                                    <input disabled={true} className="form-control form-control-solid" placeholder=""
                                           name="status" value={statusField(offerField?.offerStatus || "")}/>
                                </div>

                                <div className="col-md-6 fv-row">
                                    <label className="required fs-6 fw-semibold mb-2">Статус публикации</label>

                                    <input disabled={true} className="form-control form-control-solid" placeholder=""
                                           name="status-public"
                                           value={(isArchive ? 'Снят с продажи ' : 'В продаже до ') + new Date(offerField?.expireDate || '').toLocaleDateString()}
                                    />
                                </div>
                            </div>

                            <div className="fv-row mb-15">
                                <a href={offerField?.masterProduct?.productUrl || ""} target="_blank"
                                   className="btn btn-link btn-color-info btn-active-color-primary me-5 mb-2">{offerField?.masterProduct?.name || ""}</a>
                            </div>


                            <div className="fw-bold fs-3 rotate collapsible cursor-pointer mb-3" data-bs-toggle="collapse"
                                 data-bs-target="#mp_customer_view_details">
                                Настройки демпинга
                                <span className="ms-2 rotate-180">
                                    <KTSVG path={'assets/media/icons/duotune/arrows/arr072.svg'}
                                           className={'svg-icon-3'} />
                                </span>
                            </div>

                            <div id="mp_customer_view_details" className="collapse show">

                                {!dumpingStore &&
                                    <div
                                        className="alert alert-dismissible bg-light-info border border-info border-3 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-10">

                                        <KTSVG path={'assets/media/icons/duotune/general/gen007.svg'}
                                               className={'svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'} />

                                        <div className="d-flex flex-column pe-0 pe-sm-10">
                                            <h5 className="mb-1">Демпинг отключен</h5>
                                            <span>Для работы с демпингом, нужно включить его в настройках магазина.</span>
                                        </div>
                                    </div>
                                }

                                <div className="fv-row mb-7">
                                    <div className="d-flex flex-stack">
                                        <div className="me-5">
                                            <label className="fs-6 fw-semibold">
                                                Демпинг
                                            </label>
                                            <div className="fs-7 fw-semibold text-muted">
                                                Для корректной работы демпинга, нужно указать минимальную цену товара.
                                                <br/>
                                                <span className="form-check-label fw-semibold text-warning">
                                                    Без минимальной цены с включенным демпингом будет обновляться только место в карточке.
                                                </span>
                                            </div>
                                        </div>

                                        <label
                                            className="form-check form-switch form-check-custom form-check-solid">
                                            <input disabled={!dumpingStore} onChange={setDumping} className="form-check-input" name="dumping" type="checkbox"
                                                   value="1" id="mp_modal_edit_offer_dumping"/>

                                            <span className="form-check-label fw-semibold text-muted">
                                                {offerField?.dumping ? "Да" : "Нет"}
                                            </span>
                                        </label>
                                    </div>
                                </div>


                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                                        <div className="table-responsive">
                                            <table
                                                className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                                <thead>
                                                <tr className="border-0">
                                                    <th className="p-0 w-50px"></th>
                                                    <th className="p-0 min-w-110px">Город</th>
                                                    <th className="p-0 min-w-110px">Тек. цена</th>
                                                    <th className="p-0 min-w-110px">Мин. цена</th>
                                                    <th className="p-0 min-w-110px">Макс. цена</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {citiesJsx()}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>





                                {/*<div className="d-flex flex-column mb-7 fv-row">*/}
                                {/*    <label className="required fs-6 fw-semibold mb-2">Address Line 1</label>*/}

                                {/*    <input className="form-control form-control-solid" placeholder=""*/}
                                {/*           name="address1" value="101, Collins Street"/>*/}
                                {/*</div>*/}

                                {/*<div className="d-flex flex-column mb-7 fv-row">*/}
                                {/*    <label className="fs-6 fw-semibold mb-2">Address Line 2</label>*/}

                                {/*    <input className="form-control form-control-solid" placeholder=""*/}
                                {/*           name="address2" value=""/>*/}
                                {/*</div>*/}

                                {/*<div className="d-flex flex-column mb-7 fv-row">*/}
                                {/*    <label className="required fs-6 fw-semibold mb-2">Town</label>*/}

                                {/*    <input className="form-control form-control-solid" placeholder="" name="city"*/}
                                {/*           value="Melbourne"/>*/}
                                {/*</div>*/}

                                {/*<div className="row g-9 mb-7">*/}
                                {/*    <div className="col-md-6 fv-row">*/}
                                {/*        <label className="required fs-6 fw-semibold mb-2">State / Province</label>*/}

                                {/*        <input className="form-control form-control-solid" placeholder=""*/}
                                {/*               name="state" value="Victoria"/>*/}
                                {/*    </div>*/}

                                {/*    <div className="col-md-6 fv-row">*/}
                                {/*        <label className="required fs-6 fw-semibold mb-2">Post Code</label>*/}

                                {/*        <input className="form-control form-control-solid" placeholder=""*/}
                                {/*               name="postcode" value="3000"/>*/}
                                {/*    </div>*/}
                                {/*    */}
                                {/*    */}
                                {/*</div>*/}

                            </div>

                            <div className="separator separator-dotted border-success mb-3"></div>

                            <div className="fw-bold fs-3 rotate collapsible cursor-pointer mb-7" data-bs-toggle="collapse"
                                 data-bs-target="#mp_customer_view_details_control_balance">
                                Контроль остатков
                                <span className="ms-2 rotate-180">
                                    <KTSVG path={'assets/media/icons/duotune/arrows/arr072.svg'}
                                           className={'svg-icon-3'} />
                                </span>
                            </div>

                            <div id="mp_customer_view_details_control_balance" className="collapse show">

                                {!props.SelectedStore.controlOffersBalance &&
                                    <div
                                        className="alert alert-dismissible bg-light-info border border-info border-3 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-10">

                                        <KTSVG path={'assets/media/icons/duotune/general/gen007.svg'}
                                               className={'svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'} />

                                        <div className="d-flex flex-column pe-0 pe-sm-10">
                                            <h5 className="mb-1">Контроль остатков не активен</h5>
                                            <span>Для активации контроля остатков, нужно включить его в настройках магазина.</span>
                                        </div>
                                    </div>
                                }

                                <div className="d-flex flex-column mb-7 fv-row">
                                    <label className="required fs-6 fw-semibold mb-2">Остаток</label>

                                    <input onChange={()=>{}}
                                           disabled={!props.SelectedStore.controlOffersBalance}
                                           name={'balance'}
                                           className="form-control form-control-solid"
                                           placeholder="остаток"
                                           value={0}
                                           type={'number'}/>
                                </div>

                            </div>

                        </div>
                    </div>
                    {offerField.updateOffer &&
                        <div className="modal-footer flex-center">
                            <button onClick={closeModal} type="reset" id="mp_modal_edit_offer_cancel"
                                    className="btn btn-light me-3">Отменить
                            </button>

                            <button onClick={updateOfferFields} type="submit" id="mp_modal_edit_offer_submit" className="btn btn-primary">
                                <span className="indicator-label">Обновить</span>
                                <span className="indicator-progress">
                                    Пожалуйста ожидайте...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditOfferModal;